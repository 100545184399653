import React, { useRef, useEffect, useState, useCallback } from 'react';

function AudioDetection({ 
  brandInfo, 
  soundCount, 
  onAudioRecorded,
  resetCount = 0,
  data 
}) {
  const canvasRef = useRef(null);
  const [soundCounter, setSoundCounter] = useState(0);
  const streamRef = useRef(null);
  const audioContextRef = useRef(null);
  const animationFrameRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const analyserRef = useRef(null);
  
  // Add a ref to track the last recorded time
  const lastRecordedTimeRef = useRef(0);

  const startRecording = useCallback(() => {
    const currentTime = Date.now();

    // Check if 30 seconds have passed since last recording
    if (currentTime - lastRecordedTimeRef.current < 30000 || !data.launched) {
      return;
    }

    // Update the last recorded time
    lastRecordedTimeRef.current = currentTime;

    if (streamRef.current) {
      // Reset recorded chunks
      recordedChunksRef.current = [];

      // Create MediaRecorder with MP3 mime type
      const options = { mimeType: 'audio/mpeg' };
      
      // Fallback to default if MP3 isn't supported
      let mediaRecorder;
      try {
        mediaRecorder = new MediaRecorder(streamRef.current, options);
      } catch (e) {
        console.warn("MP3 recording not supported by this browser, trying alternative codec");
        try {
          // Try with MP4 as fallback
          mediaRecorder = new MediaRecorder(streamRef.current, { mimeType: 'audio/mp4' });
        } catch (e2) {
          // Last resort: use browser default
          mediaRecorder = new MediaRecorder(streamRef.current);
          console.warn("Using browser default codec, may not be MP3");
        }
      }

      // Event handlers for recording
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        // Get the actual mime type that was used
        const mimeType = mediaRecorder.mimeType || 'audio/mpeg';
        
        // Create blob from recorded chunks
        const audioBlob = new Blob(recordedChunksRef.current, { 
          type: mimeType 
        });

        // Call callback if provided
        if (onAudioRecorded) {
          onAudioRecorded(audioBlob, soundCount);
        }
      };

      // Start recording
      try {
        if(typeof mediaRecorder?.start === 'function') {
          mediaRecorder.start();
        }
      } catch(err) {
        console.error('Error starting media recorder:', err);
      }

      // Stop recording after 3 seconds
      setTimeout(() => {
        if (mediaRecorder.state !== 'inactive') {
          mediaRecorder.stop();
        }
      }, 3000);

      mediaRecorderRef.current = mediaRecorder;
    }
  }, [onAudioRecorded]);

  // Rest of the component remains the same as in the original code...

  // Trigger recording when sound count changes
  useEffect(() => {
    let timeoutId;
    
    if (soundCounter > 0) {
      // Start recording
      startRecording();
  
      // Set a timeout to check soundCounter after 3 seconds
      timeoutId = setTimeout(() => {
        // console.log('Checking soundCounter after 3 seconds:', soundCounter);
      }, 3000);
    }
  
    // Cleanup function to clear timeout if component unmounts or dependencies change
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [soundCounter, startRecording]);

  useEffect(() => {
    const startAudioDetection = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: true, 
          video: false 
        });
        streamRef.current = stream;

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContextRef.current = audioContext;
        const analyser = audioContext.createAnalyser();
        analyserRef.current = analyser;
        const scriptProcessor = audioContext.createScriptProcessor(1024, 1, 1);
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);

        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const canvas = canvasRef.current;
        const canvasCtx = canvas?.getContext('2d');

        const getAverageVolume = (array) => {
          return array.reduce((sum, val) => sum + val, 0) / array.length;
        };

        let makeCount = true;
        const draw = () => {
          if (stream.active && canvas && canvasCtx && analyserRef.current) {
            // Use the ref to ensure we have a valid analyser
            analyserRef.current.getByteTimeDomainData(dataArray);

            // Clear canvas
            canvasCtx.fillStyle = "rgba(0,0,0,0)";
            canvasCtx.clearRect(0, 0, canvas.height, canvas.height);

            // Draw waveform
            canvasCtx.lineWidth = 1;
            canvasCtx.strokeStyle = brandInfo?.topbar_color || '#000';
            canvasCtx.beginPath();

            const sliceWidth = canvas.height * 1.0 / bufferLength;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
              const v = dataArray[i] / 128.0;
              const y = v * canvas.height / 2;

              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
              }
              x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();

            // Sound detection
            const average = getAverageVolume(dataArray);
            if (makeCount && average >= 127.6) {
              makeCount = false;
              setSoundCounter(prev => {
                const newCount = prev + 1;
                soundCount(newCount);
                return newCount;
              });

              setTimeout(() => {
                makeCount = true;
              }, 100);
            }
          }

          animationFrameRef.current = requestAnimationFrame(draw);
        };

        draw();
      } catch (err) {
        console.error('Audio detection error:', err);
      }
    };

    startAudioDetection();

    // Cleanup
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }

      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  // Trigger recording when sound count changes
  useEffect(() => {
    let timeoutId;
    
    if (soundCounter > 0) {
      // Start recording
      startRecording();
  
      // Set a timeout to check soundCounter after 3 seconds
      timeoutId = setTimeout(() => {
        // console.log('Checking soundCounter after 3 seconds:', soundCounter);
      }, 3000);
    }
  
    // Cleanup function to clear timeout if component unmounts or dependencies change
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [soundCounter, startRecording]);

  // Reset sound counter when resetCount changes
  useEffect(() => {
    setSoundCounter(0);
  }, [resetCount]);

  return (
    <div className="audio-detect-div">
      <canvas 
        ref={canvasRef}
        width="100%" 
        height="100" 
        id="test" 
        className="audio-canvas"
      />
    </div>
  );
}

export default AudioDetection;