import React, {
    useState,
    useMemo,
} from "react";
import { Link, useParams } from "react-router-dom";
import { getRandomInt } from "../../utils/commonFunction.js";
import ReactHtmlParser from "react-html-parser";
import {
    JoinBBBClass,
    CampusClass,
    SaveFeedbackRating,
} from "../../services/ClassService";
import Swal from "sweetalert2";
import $ from "jquery";
import "../../assets/js/rating.js";
import SkeletonTicketList from "../../loaders/SkeletonTicketList.js";
import DataTable from "react-data-table-component";
import PopupComponent from "../common/PopupComponent";
import { RenderFeedAssessmentStatus, RenderFeedAssessmentType } from "../ticket/StatusRender.js";
import { twoDecimalRound } from "../../utils/commonFunction.js";
import moment from "moment";
import Countdown, { zeroPad } from "react-countdown";

const GlobalCalendarListItem = ({ row, itemType, classtype, classData, setClassData, classes, setPopupUpdate }) => {
    const [showCampuses, setShowCampuses] = useState(false)
    const [sortkey, setSortKey] = useState("date");
    const [sortOrder, setSortOrder] = useState("ASC");
    const [hoveredStar, setHoveredStar] = useState(0); // For hover effect
    const [selectedRating, setSelectedRating] = useState(0); // For selected rating
    const {qid} = useParams();


    const RenderMarkingCountdown = (marking_deadline) =>{    
        let countTimer =  Date.now() + (Date.parse(moment.unix(marking_deadline).format("DD MMM YYYY HH:mm a")) - Date.now());
        if (Date.now() > countTimer) {
          countTimer = 0;
        }
        const Renderer = ({ days, hours, minutes, seconds, completed }) => {
          if (completed) {
            return <span>{zeroPad(0)}d:{zeroPad(0)}h:{zeroPad(0)}m</span>
          } else {
            //Render a countdown
            return <span>{zeroPad(days)}d:{zeroPad(hours)}h:{zeroPad(minutes)}m</span>
                  
          }
        };
    
        return(
          countTimer ?
          <Countdown
            date={countTimer}
            renderer={Renderer}
            zeroPadTime='2'
          >
          </Countdown>
          : <Renderer completed={true} />
        )
      }

    const joinVirtualClassGlobal = (id) => {
        JoinBBBClass({ id })
            .then((res) => {
                if (res.data.error == 0) {
                    Swal.close();
                    Swal.fire({
                        icon: "success",
                        title: res.data.title,
                        text: res.message,
                        showConfirmButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.open(res.data.url, "_blank");
                            let item = res.data.class;
                            var foundIndex = classes.findIndex((x) => x.id == item.id);
                            classes[foundIndex] = item;
                            setPopupUpdate(id + getRandomInt(1));
                        }
                    });
                } else {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: res.data.title,
                        text: res.data.message,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Unknown Error.",
                }).then((result) => { });
            });
    };

    const joinCampusClassGlobal = (id) => {
        let action = "enter";
        CampusClass({ id, action })
            .then((res) => {
                if (res.data.error == 0) {
                    Swal.close();
                    Swal.fire({
                        icon: "success",
                        title: res.data.title,
                        text: res.data.message,
                        showConfirmButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let item = res.data.class;
                            var foundIndex = classes.findIndex((x) => x.id == item.id);
                            classes[foundIndex] = item;
                            setPopupUpdate(id + getRandomInt(2));
                        }
                    });
                } else {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: res.data.title,
                        text: res.data.message,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Unknown Error.",
                }).then((result) => { });
            });
    };

    const exitCampusClassGlobal = (id) => {
        let action = "exit";
        CampusClass({ id, action })
            .then((res) => {
                if (res.data.error == 0) {
                    Swal.close();
                    Swal.fire({
                        icon: "success",
                        title: res.data.title,
                        text: res.data.message,
                        showConfirmButton: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let item = res.data.class;
                            var foundIndex = classes.findIndex((x) => x.id == item.id);
                            classes[foundIndex] = item;
                            setPopupUpdate(id + getRandomInt(3));
                        }
                    });
                } else {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: res.data.title,
                        text: res.data.message,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Unknown Error.",
                }).then((result) => { });
            });
    };

    const submitRatingGlobal = (id) => {

        let student_rating = selectedRating;
        let student_comment = $("#class_comment_" + id).val();
        if (student_rating > 0 && student_comment.length > 0) {
            SaveFeedbackRating({ student_rating, student_comment, id })
                .then((res) => {
                    if (res.data.error == 0) {
                        Swal.close();
                        Swal.fire({
                            icon: "success",
                            title: res.data.title,
                            text: res.data.message,
                            showConfirmButton: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                let item = res.data.class;
                                var foundIndex = classes.findIndex((x) => x.id == item.id);
                                classes[foundIndex] = item;
                                setPopupUpdate(id + getRandomInt(4));
                            }
                        });
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: res.data.title,
                            text: res.data.message,
                            showConfirmButton: true,
                        });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Unknown Error.",
                    }).then((result) => { });
                });
        } else {
            Swal.fire({
                icon: "error",
                title: "Please provide a star rating and feedback.",
            });
        }
    };

    const handleButtonClick = (row) => {
        setClassData(row)
    }
    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };
    const breakoutColumns = useMemo(() => [
        {
            name: "Room Title",
            selector: "class_title",
            sortField: "title",
            sortable: true,
            style: {
                width: "50%",
                minWidth: "50%",
            },
            cell: (row) => (
                <>
                    <span
                        className="overflow-ellipsis2 fst_line2 feature-name fist-name-box"
                        title={row.class_title}
                    >
                        <span
                            data-toggle="modal"
                            data-target="#launch"
                            className="as-text-blue curser">
                            <span className="textLimit100">{row.class_title ? row.class_title : "-"} </span>
                        </span>
                        {row.isRecurring == 1 && row.isRecurringDifferent == 1 ? (
                            <div className="icon-w-text">
                                <span className="icon-w">
                                    <i className="fal fa-refresh not-refresh"></i>
                                </span>
                            </div>
                        ) : row.isRecurring == 1 && (
                            <div className="icon-w-text">
                                {" "}
                                <span className="icon-w">
                                    <i className="fal fa-refresh"></i>
                                </span>
                            </div>
                        )}
                    </span>
                </>
            ),
        },

        {
            name: "Recordings",
            selector: "",
            sortable: false,
            cell: (row) => {

                return (<div className="d-flex flex-column flex-md-row gapCustom-8">
                    {row.IsRecorded === 1 && row.viewURL ? (
                        <>
                            <a
                                href={row.viewURL}
                                target="_blank"
                                className="btn btn-primary"
                                title="Watch Video"
                            >
                                <i className="fal fa-play"></i> <span className="d-noneOnSmall">Watch Video</span>
                            </a>

                        </>
                    ) : (
                        <>
                            {row.IsRecorded === 1 && row.ViewAvailable == null ? (
                                <>
                                    <i className="fal fa-eye"></i>
                                    <span className="pl-1">Processing</span>
                                </>
                            ) : (
                                <>
                                    <i className="fal fa-times align-content-center"></i> &nbsp;Not Recorded
                                </>
                            )}
                        </>
                    )}

                    {row.IsRecorded === 1 && row.downloadURL ? (
                        <>
                            <a
                                href={row.downloadURL}
                                target="_blank"
                                className="btn btn-primary mt-2 mt-xl-0 download-video"
                                title="Download"
                            >
                                <i className="fal fa-download"></i> <span className="d-noneOnSmall">Download</span>
                            </a>
                        </>
                    ) : (
                        <>
                            {row.IsRecorded === 1 && row.DownloadAvailable == null ? (
                                <>
                                    <i className="fal fa-download"></i>
                                    <span className="pl-1">Processing</span>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </div>)
            }

        },
    ], []);


    let returnValue;
    let star = "";
    let notStar = "";
    let remainingRating = 5 - row.student_rating;

    for (let index = 1; index <= row.student_rating; index++) {
        star += `<i class="fas fa-star" key={row.id}></i>`;
    }
    for (let index = 1; index <= remainingRating; index++) {
        notStar += `<i class="fal fa-star" key={row.id}></i>`;
    }

    returnValue = (

        <div className="meeting-rating-start d-flex">
            <span className="stars d-flex">
                {ReactHtmlParser(star)}
                {ReactHtmlParser(notStar)}
            </span>
        </div>

    )

    const handleMouseEnter = (index) => {
        setHoveredStar(index);
    };

    const handleMouseLeave = () => {
        setHoveredStar(0);
    };

    const handleClick = (index) => {
        setSelectedRating(index);
    };

    const starRatings = () => {

        let star = "";
        let notStar = "";
        let remainingRating = 5 - classData.student_rating;

        for (let index = 1; index <= classData.student_rating; index++) {
            star += `<i class="fas fa-star" key={row.id}></i>`;
        }
        for (let index = 1; index <= remainingRating; index++) {
            notStar += `<i class="fal fa-star" key={row.id}></i>`;
        }

        return (
            <div className="meeting-rating-start d-flex">
                <span className="stars d-flex">
                    {ReactHtmlParser(star)}
                    {ReactHtmlParser(notStar)}
                </span>
            </div>
        )
    }


    return (
        <>
        {itemType == "class" ? (   
            <div className="class-list-data align-items-xl-center align-items-start flex-column flex-xl-row gap-2">
                <div className="class-info">
                    <div className="meeting-title system-bot-inner-R-title gapCustom-8">
                        {row.class_type == "hybrid" ? (
                            <>
                                <h6 className="meeting-title  pb-xl-0">
                                    {row.class_title}
                                </h6>
                                    <small className="tag ">
                                        <i className="fal fa-university"></i>{" "}
                                        <i className="fal fa-plus"></i>{" "}
                                        <i className="fal fa-house-signal"></i>Hybrid
                                    </small>
                            </>
                        ) : row.class_type == "on_campus" ? (
                            <>
                                <h6 className="meeting-title  pb-xl-0">
                                    {row.class_title}
                                </h6>
                                <small className="tag-purple">
                                    <i className="fal fa-university"></i> On Campus
                                </small>
                            </>
                        ) : (
                            <div className="virtal-class-row gap__item__10">

                                <h6 className="meeting-title  pb-xl-0">
                                    {row.class_title}
                                </h6>
                                <small className="tag-green">
                                    <i className="fal fa-house-signal"></i> Virtual
                                </small>

                            </div>)
                        }
                    </div>
                    {row?.campusArr?.length > 0 ?
                        <div className="meeting-campus">
                            <ul className="campus-list">
                                <li>
                                    <i className="fal fa-university" title="This class will be done on campus only."></i>
                                    <span>{row?.campusArr[0].room_no}, {row?.campusArr[0].campus_name}</span>
                                    &ensp;
                                    {row?.campusArr.length > 1 && showCampuses == false ?
                                        <span className="as-text-blue" onClick={() => { setShowCampuses(true) }}>
                                            {row?.campusArr.length - 1}+ Others
                                        </span>
                                        : <></>
                                    }
                                </li>

                                {showCampuses && (
                                    <>
                                        {row.campusArr.slice(1).map((item, index) => (
                                            <li key={index}>
                                                <i className="fal fa-university" title="This class will be done on campus only."></i>
                                                <span>{item.room_no}, {item.campus_name}</span>
                                            </li>
                                        ))}
                                        <li>
                                            <div className="as-text-blue" onClick={() => { setShowCampuses(false) }}>
                                                See Less
                                            </div>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                        : <></>}


                </div>

                <div className="class-action pt-xl-0 flex-column flex-xl-row align-items-start align-items-xl-center gap__item__10  ">
                    {classtype == "past" ?
                        <>
                            {row.percentage >= "1" ?
                                <>
                                    <div className="class-action-item" data-toggle="modal" data-target="#ratingsPopup" onClick={() => { handleButtonClick(row); setSelectedRating(0) }}>
                                        <span className="rating-stars">Ratings: {returnValue}</span> &ensp;

                                    </div>
                                    <PopupComponent
                                        type="customModalBody"
                                        id="ratingsPopup"
                                        header={{ iconName: "fal fa-star", heading: "Class Ratings" }}
                                        modalBody={
                                            <div>
                                                <div className="modal-body class-launch-modal">
                                                    <div className="d-flex" >
                                                        <div className="rating-stars-container">
                                                            <h4 className="landing-heading">{classData.class_title}</h4>
                                                            <br />
                                                            <span><h6>Ratings:</h6> {classData.student_rating > 0 ?
                                                                starRatings()
                                                                :
                                                                (
                                                                    // <div style={{ display: "flex", cursor: "pointer" }}>
                                                                    <div className="meeting-rating-start d-flex">
                                                                        <span className="stars">
                                                                            {[1, 2, 3, 4, 5].map((star) => (
                                                                                <i
                                                                                    key={star}
                                                                                    className={
                                                                                        star <= (hoveredStar || selectedRating) ? "fas fa-star" : "fal fa-star"
                                                                                    }
                                                                                    onMouseEnter={() => handleMouseEnter(star)}
                                                                                    onMouseLeave={handleMouseLeave}
                                                                                    onClick={() => handleClick(star)}

                                                                                />
                                                                            ))}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }</span>

                                                            {classData.student_rating > 0
                                                                ? (
                                                                    <div
                                                                        className="meeting-leave-comment-col m-0"
                                                                        key={classData.id}
                                                                    >
                                                                        <h6>Feedback:</h6>
                                                                        <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                                            <span>
                                                                                {" "}
                                                                                {classData.student_comment?.trim()}
                                                                            </span>{" "}
                                                                        </div>
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div
                                                                        className="meeting-leave-comment-col"
                                                                        key={classData.id}
                                                                    >
                                                                        <h6>Feedback:</h6>
                                                                        <div className="meeting-leave-comment">
                                                                            <textarea
                                                                                rows="2"
                                                                                className="form-control"
                                                                                placeholder="leave a comment"
                                                                                id={`class_comment_${classData.id}`}
                                                                            ></textarea>
                                                                            <a
                                                                                className="btn btn-primary submit-rating-global-list-view"
                                                                                onClick={() => {
                                                                                    submitRatingGlobal(classData.id);
                                                                                    setSelectedRating(0);
                                                                                }}
                                                                            >
                                                                                Submit Feedback
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    />
                                </>
                                : <></>
                            }

                            {row?.breakoutRooms?.length >0 ?
                                <>
                                    <button className="btn btn-primary mt-xl-0 overflow-hidden px-2" title="Class Recordings" onClick={() => handleButtonClick(row)} data-toggle="modal" data-target="#classRecordingPopUp">
                                        <i className="fal fa-video"></i> <span className="d-noneOnSmall"> Class Recordings </span>
                                    </button>

                                    <PopupComponent
                                        type="customModalBody"
                                        id="classRecordingPopUp"
                                        header={{ iconName: "fal fa-video", heading: "Class Recordings" }}
                                        modalBody={
                                            <>
                                                <div className="modal-body class-launch-modal">
                                                    <div className="d-flex" >
                                                        <h4 className="landing-heading">{classData.class_title}</h4>
                                                    </div>
                                                    <br />
                                                    <ul class="meeting-btns-list height-unchanged">
                                                        {classData.downloadURL != null ? <li><a target="_blank" href={classData.downloadURL ? classData.downloadURL : ""} class="btn btn-primary " ><i class="fal fa-download"></i> Download Video</a></li> : <></>}
                                                        {classData.recordingURL != null ? <li><a target="_blank" href={classData.recordingURL ? classData.recordingURL : ""} class="btn btn-primary " ><i class="fal fa-play"></i> Watch Video</a></li> : <></>}
                                                    </ul>
                                                    <br />

                                                    <div className={`custom-table-div filter-search-icon card-table-custom days-overdue-cell-width`} style={{ padding: "0px" }} >
                                                        <p className="mb-1"><b>Breakout Rooms</b></p>
                                                        <DataTable
                                                            // rowsPerPageOptions={[10, 15, 20, 25, 100]}
                                                            // progressPending={loading}
                                                            progressComponent={<SkeletonTicketList />}
                                                            data={classData.breakoutRooms}
                                                            columns={breakoutColumns}
                                                            defaultSortAsc={true}
                                                            defaultSortField="class_title"
                                                            onSort={handleSort}

                                                            highlightOnHover={false}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    /></>
                                : <></>
                            }
                            {row.recordings != "" &&
                                row.recordings != null ? (
                                // <div className="col-12 meeting-btns-col">

                                <div className="meeting-btns">
                                    <ul className="meeting-btns-list height-unchanged meeting-button-calendar justify-content-start justify-content-xl-end">
                                        {ReactHtmlParser(
                                            row.recordings
                                        )}
                                    </ul>
                                </div>
                                // </div>
                            ) : (
                                ``
                            )}
                        </>
                            : (classtype == "upcoming" || classtype == "live") ?
                                <>
                                    {row.class_type == "hybrid" ? (
                                        <div className="">
                                            {row.disable_online == "" ? (
                                                <button
                                                    // disabled={(row?.class_status === "Live") ? false : true}
                                                    disabled={((row?.class_status === "Live") || (row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched")) ? false : true}
                                                    data-id={`${row.id}`}
                                                    title={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ?  "Join the class virtually if you are not on campus." : "Class is not live yet."}
                                                    onClick={() =>
                                                        joinVirtualClassGlobal(row.id)
                                                    }
                                                    className={`btn btn-primary mr-2 join-virtual-classes-list-view`}
                                                >
                                                    <i className="fal fa-wifi"></i>
                                                    Join Virtual Class
                                                </button>
                                            ) : (
                                                <button
                                                    data-id={`${row.id}`}
                                                    title="Join the class virtually if you are not on campus."
                                                    onClick={() =>
                                                        joinVirtualClassGlobal(row.id)
                                                    }
                                                    disabled
                                                    className={`btn btn-primary mr-2 join-virtual-classes-list-view disabled`}
                                                >
                                                    <i className="fal fa-wifi"></i>
                                                    Join Virtual Class
                                                </button>
                                            )}

                                            {row.disable_campus != "" &&
                                                row.hide_checkin != "" ? (
                                                <button
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    disabled
                                                    onClick={() =>
                                                        joinCampusClassGlobal(row?.id)
                                                    }
                                                    style={{ display: "none" }}
                                                    className={`btn btn-primary mr-2 join-campus-classes-list-view disabled`}
                                                >
                                                    <i className="fal fa-university"></i>
                                                    Check In On Campus
                                                </button>
                                            ) : row.disable_campus != "" &&
                                                row.hide_checkin == "" ? (
                                                <button
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    onClick={() =>
                                                        joinCampusClassGlobal(row?.id)
                                                    }
                                                    disabled
                                                    className={`btn btn-primary mr-2 join-campus-classes-list-view disabled`}
                                                >
                                                    <i className="fal fa-university"></i>
                                                    Check In On Campus
                                                </button>
                                            ) : row.disable_campus == "" &&
                                                row.hide_checkin != "" ? (
                                                <button
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    style={{ display: "none" }}
                                                    onClick={() =>
                                                        joinCampusClassGlobal(row?.id)
                                                    }
                                                    className={`btn btn-primary mr-2 join-campus-classes-list-view`}
                                                >
                                                    <i className="fal fa-university"></i>
                                                    Check In On Campus
                                                </button>
                                            ) : (
                                                <button
                                                    // disabled={(row?.class_status === "Live") ? false : true}
                                                    disabled={((row?.class_status === "Live") || (row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched")) ? false : true}
                                                    data-id={`${row.id}`}
                                                    title={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ?  "Check in here if you are attending the class on campus." : "Class has not started yet."}
                                                    className={`btn btn-primary mr-2 join-campus-classes-list-view`}
                                                    onClick={() =>
                                                        joinCampusClassGlobal(row?.id)
                                                    }
                                                >
                                                    <i className="fal fa-university"></i>
                                                    Check In On Campus
                                                </button>
                                            )}

                                            {row.hide_checkout == "" ? (
                                                <button
                                                    data-id={`${row.id}`}
                                                    title="On Campus Check Out"
                                                    className="btn btn-primary mr-2 exit-campus-class exit-campus-classes-list-view"
                                                    onClick={() =>
                                                        exitCampusClassGlobal(row?.id)
                                                    }
                                                >
                                                    <i
                                                        className="fal fa-window-close"
                                                        aria-hidden="true"
                                                    ></i>
                                                    On Campus Check Out
                                                </button>
                                            ) : (
                                                <button
                                                    data-id={`${row.id}`}
                                                    title="On Campus Check Out"
                                                    style={{ display: "none" }}
                                                    onClick={() =>
                                                        exitCampusClassGlobal(row?.id)
                                                    }
                                                    className="btn btn-primary mr-2 exit-campus-class exit-campus-classes-list-view"
                                                >
                                                    <i
                                                        className="fal fa-window-close"
                                                        aria-hidden="true"
                                                    ></i>
                                                    On Campus Check Out
                                                </button>
                                            )}
                                        </div>
                                    ) : row.class_type == "on_campus" ? (
                                        <div className="">
                                            {row.hide_checkin == "" ? (
                                                <button
                                                    // disabled={(row?.class_status === "Live") ? false : true}
                                                    disabled={((row?.class_status === "Live") || (row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched")) ? false : true}
                                                    data-id={`${row.id}`}
                                                    title={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ?  "Check in here if you are attending the class on campus." : "Class has not started yet."}
                                                    className="btn btn-primary mr-2 join-campus-classes-list-view"
                                                    onClick={() =>
                                                        joinCampusClassGlobal(row?.id)
                                                    }
                                                >
                                                    <i className="fal fa-university"></i>
                                                    Check In On Campus
                                                </button>
                                            ) : (
                                                <button
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    onClick={() =>
                                                        joinCampusClassGlobal(row?.id)
                                                    }
                                                    style={{ display: "none" }}
                                                    className="btn btn-primary mr-2 join-campus-class join-campus-classes-list-view"
                                                >
                                                    <i className="fal fa-university"></i>
                                                    Check In On Campus
                                                </button>
                                            )}

                                            {row.hide_checkout == "" ? (
                                                <button
                                                    data-id={`${row.id}`}
                                                    title="On Campus Check Out"
                                                    className="btn btn-primary mr-2 exit-campus-class exit-campus-classes-list-view"
                                                    onClick={() =>
                                                        exitCampusClassGlobal(row?.id)
                                                    }
                                                >
                                                    <i
                                                        className="fal fa-window-close"
                                                        aria-hidden="true"
                                                    ></i>
                                                    On Campus Check Out
                                                </button>
                                            ) : (
                                                <button
                                                    data-id={`${row.id}`}
                                                    title="On Campus Check Out"
                                                    className="btn btn-primary mr-2 exit-campus-class exit-campus-classes-list-view"
                                                    style={{ display: "none" }}
                                                    onClick={() =>
                                                        exitCampusClassGlobal(row?.id)
                                                    }
                                                >
                                                    <i
                                                        className="fal fa-window-close"
                                                        aria-hidden="true"
                                                    ></i>
                                                    On Campus Check Out
                                                </button>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="">
                                            <button
                                                // disabled={(row?.class_status === "Live") ? false : true}
                                                disabled={((row?.class_status === "Live") || (row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched")) ? false : true}
                                                data-id={`${row.id}`}
                                                title={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ?  "Join the class virtually if you are not on campus." : "Class has not started yet."}
                                                onClick={() =>
                                                    joinVirtualClassGlobal(row.id)
                                                }
                                                className="btn btn-primary mr-2 join-virtual-classes-list-view "
                                            >
                                                <i className="fal fa-wifi"></i>
                                                Join Virtual Class
                                            </button>
                                        </div>
                                    )}
                                </>
                                : <></>}

                </div>

            </div>
        ) : itemType == "assessment" ? (
            <div className={`class-list-data align-items-lg-center flex-column flex-lg-row gap-2`}>
                <div className="class-info">
                    <div className="system-bot-inner-R-title d-flex flex-column flex-xl-row gap-2 align-items-start align-items-xl-center">
                        <h6 className="meeting-title pb-2 pb-xl-0">
                            {row?.name}
                        </h6>
                            <span className="lib-con">
                                {RenderFeedAssessmentType(row?.ass_type).html}
                            </span>  
                        <div className="meeting-title pt-2 pt-xl-0">
                                                                                          
                            {RenderFeedAssessmentStatus(row?.status).html}
                        
                            { row?.ass_type != "Survey" ?(row?.status=="Open" ?
                                        <span className="assessment-conditional-detail">
                                        <i className="fal fa-clock"></i> {`${row?.timeHour.toString().padStart(2,'0')}h:${row?.timeMin.toString().padStart(2,'0')}m`}  
                                        &ensp;
                                        <i className="fal fa-calendar-exclamation item-text-red"></i> {`${moment.unix(row.close_date_time).format("dddd, DD MMM YY, HH:mm")}`}                               
                                        </span>
                                        : (row?.status == "Closed-Submitted" || row?.status=="Submitted") ? (row?.assCalculateGradeStatus?.gradeText == 'witheld' || (row?.grademixCulc?.length > 0 && row?.grademixCulc[0].gradeText == 'witheld')) ? 
                                        (<><br/><span className="assessment-conditional-detail"><i className="fal fa-ballot-check"></i> Result:</span>
                                        <span 
                                            title={'Your marks are being withheld due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket'} 
                                            className={`as-red-bg as-widget`}>
                                            <i className={`fal fa-ban`}></i> {"WITHHELD"}
                                        </span></>)
                                        :(<span className="assessment-conditional-detail">
                                        <i class="fal fa-calendar-edit"></i> {RenderMarkingCountdown(row?.marking_deadline_date)}
                                        </span>)                                   
                                        :(row?.status=="Closed-Marked" || row?.status=="Closed Not-Submitted") && row?.ass_type!="Survey" ?                                   
                                        (row?.assCalculateGradeStatus?.gradeText == 'witheld' || (row?.grademixCulc?.length > 0 && row?.grademixCulc[0].gradeText == 'witheld')) ? 
                                        (<><br/><span className="assessment-conditional-detail"><i className="fal fa-ballot-check"></i> Result:</span>
                                        <span 
                                            title={'Your marks are being withheld due to outstanding balances on your account, you will have limited access to the portal. Please contact the finance department by logging a ticket'} 
                                            className={`as-red-bg as-widget`}>
                                            <i className={`fal fa-ban`}></i> {"WITHHELD"}
                                        </span></>)
                                        : (!(row?.assCalculateGradeStatus) || row?.grademixCulc?.length > 0) ? (
                                        <>
                                        <br/><span className="assessment-conditional-detail"><i className="fal fa-ballot-check"></i> Result:</span>
                                        <span 
                                        title={"Open assessment to view your result"}
                                            className={`as-green-bg as-widget`}>
                                            <i className={`fal fa-file-check`}>
                                            </i> {"Available"}
                                        </span>
                                        </>
                                        )
                                        : (<><br/><span className="assessment-conditional-detail">
                                        <i className="fal fa-ballot-check"></i> Result: 
                                        <span className="font-weight-bold">
                                            {row?.grading_type === 3 ? 
                                            ` ${twoDecimalRound(row?.assCalculateGradeStatus?.gradeText)}/${row?.subAssTotMark}` 
                                            : row?.grading_type === 0 ? 
                                            ` ${twoDecimalRound(row?.assCalculateGradeStatus?.grade)}%` 
                                            : ` ${(row?.assCalculateGradeStatus?.gradeText)}`
                                            }
                                        </span>
                                        </span></>)
                                        :null)
                                        :null}
                        </div>
                    </div>
                </div>
                <div className="class-action pt-2 pt-xl-0 feed-assessment-action">
                    <Link 
                        className="btn btn-primary overflow-hidden"
                        target="_blank"
                        to={`/course/${qid || row?.qualificationId}/subject/${row?.intake}/assessment/${row?.id}`}
                    >
                    <i className="fal fa-file-alt"></i> View Assessment
                  </Link>
                </div>
            </div>
        ) : (
            <></>
        )}
        </>
    )
}

export default GlobalCalendarListItem;