import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { download } from "../../utils/FileDownload";
import moment from "moment";
import randomColor from "randomcolor";
import PuzzleImg from '../../assets/images/puzzle-icon.svg';
import { TrimText } from "../common/TrimText";
import CommonCourseComponents from "./CommonCourseComponents";
import { formatDateRange, getSubjectStatus } from "../../utils/commonFunction";
import ViewProfile from "./ViewProfile";
const stc = require('string-to-color');
const downloadFile = async (absolutePath, fileName) => {
    await download(absolutePath, fileName);
};


export default function PastQualificationBox({ state }) {

    function createAbbreviation(name = "") {
        return name.split(" ")[0].toString().toUpperCase().substring(0, 1) + (name.split(" ")[1] ? name.split(" ")[1].toString().toUpperCase().substring(0, 1) : "");
    }

    const calculateDuration = (start, end) => {
        const startMoment = moment.unix(start);
        const endMoment = moment.unix(end);
        const duration = moment.duration(endMoment.diff(startMoment));

        const years = Math.floor(duration.asYears());
        const months = Math.floor(duration.asMonths()) % 12;
        const days = Math.floor(duration.asDays()) % 30;

        const parts = [];
        if (years > 0) parts.push(`${years}${years > 0 ? 'y' : ''}`);
        if (months > 0) parts.push(`${months}${months > 0 ? 'm' : ''}`);
        if (days > 0) parts.push(`${days}${days > 0 ? 'd' : ''}`);

        return parts.join(' ');
    };

    const handleCardClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };

    return (
        <>
            <div className="tab-content" id="pills-tabContent">
                <div className="mycourses-ul-list m-0 d-block">
                    <div className="row row-custome couses-newrw-lists">
                        {!state?.showQual ? (
                            <>
                                {state?.pastShortCourse?.map((ele, ind) => {
                                    var icon = getSubjectStatus(ele?.icon);
                                    return (
                                        <div className="col-lg-3 col-md-6 col-xs-12 qualification-box" key={ind} title={ele?.course}>
                                            <div className="couses-renew-card home-bx-card qualification-card" onClick={handleCardClick}>
                                                <Link to={`/shortCourse/${ele?.id}`} className="d-flex flex-column justify-content-between h-100">
                                                    <div className="d-flex flex-column">
                                                        {ele?.code ? <div className="couses-card">
                                                            <div className="short-course-title" title={ele?.code}>
                                                                <span className="tittle">{ele?.code ? ele?.code : "NA"}</span>
                                                            </div>
                                                            {ele?.commentCount != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                                                                <span className="nav-fa-comments card-comments">
                                                                    <small className="notify-count">{ele?.commentCount ? ele?.commentCount < 10 ? ele?.commentCount : "9+" : 0}</small>
                                                                    <i className="fal fa-comments notification-icon"></i>
                                                                </span>
                                                            </div>}
                                                        </div> : null}

                                                        <div className="program-tittle">
                                                            <h2 title={ele?.course ? ele?.course : ""}>{ele?.course ? TrimText(ele?.course, 35) : ""}</h2>
                                                            {!ele?.code && ele?.commentCount != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                                                                <span className="nav-fa-comments card-comments">
                                                                    <small className="notify-count">{ele?.commentCount ? ele?.commentCount < 10 ? ele?.commentCount : "9+" : 0}</small>
                                                                    <i className="fal fa-comments notification-icon"></i>
                                                                </span>
                                                            </div>}
                                                        </div>
                                                        <p className="program-type">
                                                            {/* <span className="couse_i_in"><i className="fal fa-book"></i></span> */}
                                                            {ele?.type ? ele?.type : ""}
                                                        </p>

                                                        <div className="d-flex align-items-center">
                                                            {ele?.type === "Self-Paced" ? (
                                                                <p className="course__card__duration">
                                                                    <span className="couse_i_in">
                                                                        <i className="fal fa-clock"></i>
                                                                    </span>
                                                                    {ele?.subject_duration ? `${ele?.subject_duration} months` : "-"}
                                                                </p>
                                                            ) : (
                                                                <p className="course__card__duration">
                                                                    <span className="couse_i_in">
                                                                        <i className="fal fa-calendar-alt" style={{ marginRight: "5px" }}></i>
                                                                    </span>
                                                                    {ele?.start && ele?.end ? (
                                                                        <>
                                                                            {formatDateRange(ele?.start, ele?.end)}
                                                                        </>
                                                                    ) : "-"}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="qualification-view align-items-start">
                                                            {ele?.intake_coach_name ? <ViewProfile user={{
                                                                lead_photo: ele?.intake_coach_photo,
                                                                prog_lead_name: ele?.intake_coach_name,
                                                                lead_activity_status: ele?.intake_coach_status,
                                                                lead_email: ele?.intake_coach_email,
                                                                lead_mobile: ele?.intake_coach_mobile,
                                                                supp_photo: ele?.intake_supp_photo,
                                                                supp_fullname: ele?.intake_supp_name,
                                                                supp_activity_status: ele?.intake_supp_status,
                                                                supp_email: ele?.intake_supp_email,
                                                                supp_mobile: ele?.intake_supp_mobile,
                                                            }} ele={ele} cardParent={true}
                                                                viewTimeTable={((ele?.fileName && ele?.file) || ele?.timeTable) ? true : false}
                                                            /> : null}

                                                            {((ele?.fileName && ele?.file) || ele?.timeTable) && (
                                                                <div className="quali_couses_ptg">
                                                                    <button
                                                                        title="Download Timetable"
                                                                        className="btn download_btn"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            downloadFile(ele?.file, ele?.fileName);
                                                                        }}
                                                                    >
                                                                        <i className="fal fa-calendar-alt"></i> View Timetable
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="qualification-box-progress">
                                                            <div className="d-flex align-items-center" style={{ margin: "0px 0" }}>
                                                                <p className="status" style={{ fontWeight: "600", fontSize: "10px", lineHeight: "10px", marginBottom: "0" }}>{ele?.progress.progressBar}% Complete</p>
                                                                <div className="d-flex align-items-center" style={{ paddingLeft: "10px" }}>
                                                                    <img src={icon} alt="" width="10px" height="10px" />
                                                                    <p className="course-status">{ele?.subjectStatus || "NA"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="progress">
                                                                <div
                                                                    className={"progress-bar zero-progress bar-data"}
                                                                    role="progressbar"
                                                                    style={{width: (ele?.progress.progressBar || 15) + "%",
                                                                    }}
                                                                    aria-valuenow="25"
                                                                    aria-valuemin="0"
                                                                    title={`${ele?.progress.progressBar}%`}
                                                                    aria-valuemax="100"
                                                                >
                                                                    {ele?.progress.progressBar}%
                                                                </div>
                                                            </div>

                                                            <div className="program-statistics-count">
                                                                <ul className="num-info">
                                                                    {ele?.progress.total_videos > 0 && (
                                                                        <li title="Videos" className="videoes">
                                                                            <i className="fal fa-video" />
                                                                            <span className="value">{ele?.progress.total_videos}</span>
                                                                        </li>
                                                                    )}
                                                                    {ele?.progress.total_quizs > 0 && (
                                                                        <li title="Quizzes" className="Quizzes">
                                                                            <i className="fal fa-clipboard-list" />
                                                                            <span className="value">{ele?.progress.total_quizs}</span>
                                                                        </li>
                                                                    )}
                                                                    {ele?.progress.total_documents > 0 && (
                                                                        <li title="Files" className="files">
                                                                            <i className="fal fa-file" />
                                                                            <span className="value">{ele?.progress.total_documents}</span>
                                                                        </li>
                                                                    )}
                                                                    {ele?.progress.total_assessment > 0 && (
                                                                        <li title="Assessment" className="files">
                                                                            <i className="fal fa-ballot" />
                                                                            <span className="value">{ele?.progress.total_assessment}</span>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        ) :
                            <>
                                {state?.pastQualification?.map((ele, ind) => {
                                    return (
                                        <div className="col-lg-3 col-md-6 col-xs-12 qualification-box" key={ind} title={ele?.course}>
                                            <div className="couses-renew-card home-bx-card qualification-card" onClick={handleCardClick}>
                                                <Link to={`/mycourses/${ele?.id}`} className="d-flex flex-column justify-content-between h-100">
                                                    <div className="d-flex flex-column">
                                                        {ele?.program_type_name ? <div className="couses-card">
                                                            <CommonCourseComponents degree={ele?.program_type_name} />
                                                            {ele?.commentCount != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                                                                <span className="nav-fa-comments card-comments">
                                                                    <small className="notify-count">{ele?.commentCount ? ele?.commentCount < 10 ? ele?.commentCount : "9+" : 0}</small>
                                                                    <i className="fal fa-comments notification-icon"></i>
                                                                </span>
                                                            </div>}
                                                        </div> : null}

                                                        <div className="program-tittle">
                                                            <h2 title={ele?.course ? ele?.course : "NA"}>{ele?.course ? TrimText(ele?.course, 35) : "NA"}</h2>
                                                            {!ele?.program_type_name && ele?.commentCount != 0 && <div className="nav-tpFixed-comments card-comment-box" title='You have unread messages'>
                                                                <span className="nav-fa-comments card-comments">
                                                                    <small className="notify-count">{ele?.commentCount ? ele?.commentCount < 10 ? ele?.commentCount : "9+" : 0}</small>
                                                                    <i className="fal fa-comments notification-icon"></i>
                                                                </span>
                                                            </div>}
                                                        </div>
                                                        <p className="program-type" title={`${ele?.type && ele?.number && ele?.year ? `${ele?.type}, Intake ${ele?.number}, ${ele?.year}` : '-'}}`}>
                                                            {ele?.type && ele?.number && ele?.year ? `${ele?.type}, Intake ${ele?.number}, ${ele?.year}` : '-'}
                                                        </p>



                                                        <div className="d-flex align-items-center">
                                                            {ele?.type === "Self-Paced" ? (
                                                                <p style={{ marginRight: "auto", fontSize: "12px", fontWeight: "600", color: "#467FCF", paddingTop: "5px", paddingBottom: "auto", margin: "0px" }} title={`${ele?.subject_duration ? `${ele?.subject_duration} months` : "-"}`}>
                                                                    <span className="couse_i_in">
                                                                        <i className="fal fa-clock"></i>
                                                                    </span>
                                                                    {ele?.subject_duration ? `${ele?.subject_duration} months` : "-"}
                                                                </p>
                                                            ) : (
                                                                <p className="course__card__duration" title={ele?.start && ele?.end ? formatDateRange(ele?.start, ele?.end) : "-"}>
                                                                    <span className="couse_i_in">
                                                                        <i className="fal fa-calendar-alt" style={{ marginRight: "5px" }}></i>
                                                                    </span>
                                                                    {ele?.start && ele?.end ? (
                                                                        <>
                                                                            {formatDateRange(ele?.start, ele?.end)}
                                                                        </>
                                                                    ) : "Invalid Date"}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="qualification-view align-items-start reset-min-height">
                                                            {/* {ele?.prog_lead_name ? <ViewProfile user={{
                                                                lead_photo: ele?.prog_lead_photo,
                                                                prog_lead_name: ele?.prog_lead_name,
                                                                lead_activity_status: ele?.prog_lead_active == 1 ? "online" : "offline",
                                                                lead_email: ele?.prog_lead_email,
                                                                lead_mobile: ele?.prog_lead_mobile,
                                                                supp_photo: ele?.prog_supp_photo,
                                                                supp_fullname: ele?.prog_supp_name,
                                                                supp_activity_status: ele?.prog_supp_active == 1 ? "online" : "offline",
                                                                supp_email: ele?.prog_supp_email,
                                                                supp_mobile: ele?.prog_supp_mobile,
                                                            }} ele={ele} cardParent={true}
                                                                viewTimeTable={((ele?.fileName && ele?.file) || ele?.timeTable) ? true : false}
                                                            /> : null} */}

                                                            {((ele?.fileName && ele?.file) || ele?.timeTable) && (
                                                                <div className="quali_couses_ptg">
                                                                    <button
                                                                        title="Download Timetable"
                                                                        className="btn download_btn"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            downloadFile(ele?.file, ele?.fileName);
                                                                        }}
                                                                    >
                                                                        <i className="fal fa-calendar-alt"></i> View Timetable
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="qualification-box-progress">
                                                            <p className="status" style={{ fontWeight: "600", fontSize: "10px", lineHeight: "10px" }} title={`${ele?.progress.progressBar}% Complete`}>{ele?.progress.progressBar}% Complete</p>

                                                            <div className="progress">
                                                                <div
                                                                    className={"progress-bar zero-progress bar-data"}
                                                                    role="progressbar"
                                                                    style={{width: (ele?.progress.progressBar || 15) + "%",
                                                                    }}
                                                                    aria-valuenow="25"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    title={`${ele?.progress.progressBar}%`}
                                                                >
                                                                    {ele?.progress.progressBar}%
                                                                </div>
                                                            </div>

                                                            <div className="program-statistics-count">
                                                                <ul className="num-info">
                                                                    {ele?.progress.total_videos > 0 && (
                                                                        <li title="Videos" className="videoes">
                                                                            <i className="fal fa-video" />
                                                                            <span className="value">{ele?.progress.total_videos}</span>
                                                                        </li>
                                                                    )}
                                                                    {ele?.progress.total_quizs > 0 && (
                                                                        <li title="Quizzes" className="Quizzes">
                                                                            <i className="fal fa-clipboard-list" />
                                                                            <span className="value">{ele?.progress.total_quizs}</span>
                                                                        </li>
                                                                    )}
                                                                    {ele?.progress.total_documents > 0 && (
                                                                        <li title="Files" className="files">
                                                                            <i className="fal fa-file" />
                                                                            <span className="value">{ele?.progress.total_documents}</span>
                                                                        </li>
                                                                    )}
                                                                    {ele?.progress.total_assessment > 0 && (
                                                                        <li title="Assessment" className="files">
                                                                            <i className="fal fa-ballot" />
                                                                            <span className="value">{ele?.progress.total_assessment}</span>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>}
                    </div>
                </div>
            </div>
        </>
    )
}
