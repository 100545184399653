import axiosInstance from "../utils/axios";

// export const GetMyQualificationService = async () => {
//   return await axiosInstance().get("/getMyQualifications/");
// };
export const GetMyQualificationService = async (values) => {
  return await axiosInstance().get("/getStudentQualifications",{ params: values })
};
export const GetMyPastQualificationService = async (values) => {
  return await axiosInstance().get("/getStudentQualifications",{ params: values })
};

export const GetShortCourseQualification = async (values) => {
  return await axiosInstance().get("/getStudentShortCourses",{ params: values })
};

export const GetMyTimeTableService = async (values) => {
  return await axiosInstance().post("/getMyTimeTable/", values);
};

export const GetSubjectsService = async (values) => {
  return await axiosInstance().get("/getMySubjects", { params: values });
};

export const GetMySubjectsService = async (values) => {
  return await axiosInstance().get("/getQualificationSubjects", { params: values });
};

export const getSubjects = async (values) => {
  return await axiosInstance().get("/getSubjects", { params: values });
};

export const GetSubjectContentService = async (values) => {
  return await axiosInstance().get("/getSubjectContent", { params: values });
};
export const GetSubjectContentServiceAdmin = async (values) => {
  return await axiosInstance().get("/getSubjectContentAdmin", { params: values });
};

export const GetSupportTeamService = async (values) => {
  return await axiosInstance().get("/getSupportTeam", { params: values });
};
// Assignment Sections Starts Here

export const GetSubjectAssignments = async (values) => {
  return await axiosInstance().get("/getSubjectAssignments", {
    params: values,
  });
};

export const getAssignmentsFiles = async (values) => {
  return await axiosInstance().get("/getAssignmentsFiles", {
    params: values,
  });
};

// export const getSubjectAssignmentsByFilter = async (values) => {
//   return await axiosInstance().get("/getSubjectAssignmentsByFilter", {
//     params: values,
//   });
// };
// export const GetAssignmentDetailByStatus = async (values) => {
//   return await axiosInstance().get("/getAssignmentDetail", {
//     params: values,
//   });
// };

// export const GetAssignmentDetail = async (values) => {
//   return await axiosInstance().get("/getAssignmentDetail", { params: values, });
// };
export const GetAssignmentDetail = async (values) => {
  return await axiosInstance().get("/getAssignmentGroupDetail", { params: values, });
};

export const AssignmentSubmission = async (values) => {
  return await axiosInstance().post(`/assignmentSubmission`, values);
};
export const RetryQuiz = async (values) => {
  return await axiosInstance().post(`/retryQuizAttempts`, values );
};
export const SetToFinal = async (values) => {
  return await axiosInstance().get(`/setToFinal`, { params: values });
};
export const UploadFile = async (values) => {
  return await axiosInstance().post(`/uploadFile`, values);
};

export const RemoveFile = async (values) => {
  return await axiosInstance().get(`/removeFile`, { params: values });
};
export const StoreTime = async (values) => {
  return await axiosInstance().get(`/storeTime`, { params: values });
};

export const AppealSubmission = async (values) => {
  return await axiosInstance().post(`/appealSubmission`, values);
};

export const GetPostFeedMessages = async (values) => {
  return await axiosInstance().get(`/getPostFeedMessages`, { params: values });
}

export const GetPostFeedMessagesPaginate=async (values, page, limit=10, cancelToken) => {
  return await axiosInstance().get(`/getPostFeedMessagesPaginateTz?page=${page}&limit=${limit}&needOnlineClass=1`, { params: values, cancelToken: cancelToken });
}

export const GetCommentMessages = async (values) => {
  return await axiosInstance().get(`/getCommentMessages`, { params: values });
}
// Assignment Sections Starts Here

export const UploadIdentificationImage = async (values) => {
  return await axiosInstance().post(`/updateMyExamIdentification`, values);
};

// OLD UNREADMESSAGE COUNT API BEFORE UI/UI
// export const GetMyUnreadChatNotificationCount = async () => {
//   return await axiosInstance().get("/getAllUnreadCommentMessagesCount");
// };

// NEW UNREADMESSAGE COUNT API AFTER UI/UI
export const GetMyUnreadChatNotificationCount = async () => {
  return await axiosInstance().get("/getAllNewsRoomUnreadMessageCount");
};

export const readCommentMessages = async (values) => {
  return await axiosInstance().post(`/readCommentMessages`, values);
};

export const generatePdfForCurriculumNew = async (values) => {
  return await axiosInstance().post(`/generatePdfForCurriculumNew`, values);
};

export const GetMentionedUserDetails = async (id) => {
  return await axiosInstance().get(`/getStudentProfile?id=${id}`)
}

export const readAllCommentMessages = async (values) => {
  return await axiosInstance().post("/readAllCommentMessages", values);
};

export const GetCourseStatistics = async (param) => {
  return await axiosInstance().get("/getCourseStatistics", {params:param});
};

export const GetCourseResourceDocumentsCount = async (param) => {
  return await axiosInstance().get("/getCourseResourceDocumentsCount", {params:param});
};

export const GetPinnedMessages = async (params) => {
  return await axiosInstance().get("/getPinnedMessages", {params});
};

export const AssessmentAuditTracks = async (values) => {
  return await axiosInstance().post("/assessmentAuditTracks", values);
}