import HackTimer from "../../../utils/HackTimer";
import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Str from "../../common/Str";
// import Exam_recording from "../../../pages/object-detection/Exam_recording";
import AssignmentStatus from "../../course/AssignmentStatus";
import AssignmentType from "../../course/AssignmentType";
import { zeroPad } from "react-countdown";
import { SystemIcons } from "../../common/SystemIcons";
import { SysCheckSideIcon } from "../../common/SysCheckSideIcon";
import { RenderAttachList } from "../../common/RenderAttachList";
import Swal from "sweetalert2";
import { AssignmentSubmission, GetAssignmentDetail, SetToFinal } from "../../../services/QualificationService";
import ViewQuestion from "../quiz/ViewQuestionExam";
import { useDispatch, useSelector } from "react-redux";
import { setIsExamLaunched } from "../../../store/actions";
import { useHistory } from "react-router-dom";
import $ from 'jquery';
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { calculateTimeLeftOpt } from "../../../utils/CommonGroupingItem";
const Step5 = (props) => {
  const [showFile, setShowFile] = useState(true);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(null);
  const [quizArr, setQuizArr] = useState(props.quizArr);
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [open, setOpen] = useState(true);
  const  [gradeType, setGradeType] = useState(null);
  const {quizTotalTime, isTimer} = props;
  const { examLaunched } = useSelector(state=>state.examLaunched);
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasBeenSetToFinal, setHasBeenSetToFinal] = useState(false);
  const isExamLaunched = history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched;
  const examPopup = () => {dispatch(setIsExamLaunched({...examLaunched, isClicked: true}))};
  const [submitQuizAssessment, setSubmitQuizAssessment] = useState(false);
  const [fileInProgress, setFileInProgress] = useState(0);
  const [fileStatus, setFileStatus] = useState({});
  const [assessmentStatus, setAssessmentStatus] = useState(props.data.status ? props.data.status : "");
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [timeLeft, setTimeLeft] = useState("00:00:00");
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [dropzoneFileInfo, setDropzoneFileInfo] = useState([]);
  const dropzoneFileInfoRef = useRef(dropzoneFileInfo);

  // console.log("props?.data", props?.data)
  

  // useEffect(() => {
  //   console.log("timeLeft", timeLeft)
  //   console.log("hours", hours)
  //   console.log("minutes", minutes)
  //   console.log("seconds", seconds)
  //   console.log("props?.data.remainingDuration", props?.data.remainingDuration)
  // }, [hours, minutes, seconds, timeLeft, props?.data.remainingDuration]);

  useEffect(() => {
    if(!props.isRecord && !props.screenShareCheck && props.data.screen_share){
       dispatch(setIsExamLaunched({...examLaunched, isExamLaunched: false, isClicked: false }));
        props.parentCall(); 
    }
  },[props.isRecord,props.screenShareCheck])

  useEffect(() => {
    if(props?.submitQuizAssessment){
      setSubmitQuizAssessment(props.submitQuizAssessment);
    }
  }, [props?.submitQuizAssessment]);

  const updateSubmitQuizAssessment = () => {
    props.updateSubmitQuizAssessment();
    setSubmitQuizAssessment(false);
  }

  useEffect(()=>{
    if(isTimeUp && props?.data.launched === 1 && !hasBeenSetToFinal){
      if(props.data.quiz_id == 0){
        if(fileInProgress<=0 && props.fileSubmitted==true){
          props.setToFinal(true);
          setHasBeenSetToFinal(true);
        }
      } else {
        setSubmitQuizAssessment(true);
        setHasBeenSetToFinal(true);
      }
    } 
  },[props, hasBeenSetToFinal, setHasBeenSetToFinal, isTimeUp]);

  useEffect(() => {
    if (props?.data?.timeLimitActive && props?.data?.launched === 1 && props?.data.remainingDuration > 0 ) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            if (hours === 0) {
              // props.setAssignmentReload(getRandomInt(5000));
              if(props.data.quiz_id > 0 && !hasBeenSetToFinal){
                // console.log("_____________ Run S5 1")
                // setSubmitQuizAssessment(true);
                setHasBeenSetToFinal(true)
              } else if(!hasBeenSetToFinal) {
                if(fileInProgress<=0 ){
                  if(props.fileSubmitted==true){
                    props.setToFinal(true);
                    setHasBeenSetToFinal(true)                                      
                  }else{
                    $(".submit-file-button").click();                  
                  }
                }else{
                  props.setFileSubmitted(false);
                  setDisableFileUpload(true);
                }                
              }
              clearInterval(myInterval)
            }
            else {
              setHours(hours - 1);
              setMinutes(59)
              setSeconds(59);
            }

          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  useEffect(() => {
    if (props.assessmentId) {
      let aid = props.assessmentId;
      let tId = setTimeout(() => {
        GetAssignmentDetail({ aid })
          .then((res) => {
              if (res.data && res.data.remainingDuration > 0) {
                var hoursDifference = ~~(res.data.remainingDuration / 3600);
                var minutesDifference = ~~((res.data.remainingDuration % 3600) / 60);
                var secondsDifference = ~~res.data.remainingDuration % 60;
  
                // setHours(hoursDifference)
                // setMinutes(minutesDifference)
                const remainingTime = calculateTimeLeft(res.data, true);
                setTimeLeft(remainingTime)
                const {hours, minutes, seconds} = calculateTimeLeft(res.data);
                setHours(hours)
                setMinutes(minutes)
                setSeconds(seconds)
              } else {
                const remainingTime = calculateTimeLeft(res.data, true);
                setTimeLeft(remainingTime)
                const {hours, minutes, seconds} = calculateTimeLeft(res.data);
                setHours(hours)
                setMinutes(minutes)
                setSeconds(seconds)
            }
          })
          .catch((error) => { });
      }, 1000);

      return ()=>{clearTimeout(tId)}
    }
  }, []);

  useEffect(() => {
    let { hours, minutes, seconds } = calculateTimeLeft(props.data);
    if (((hours * 60 * 60) + (minutes * 60) + seconds) > 0 && ((hours * 60 * 60) + (minutes * 60) + seconds) <= 180 && props?.data.launched && props?.data.timeLimitActive) {
      if (((hours * 60 * 60) + (minutes * 60) + seconds) === 180 || ((hours * 60 * 60) + (minutes * 60) + seconds) === 60) {
        Swal.fire(`<p style='color:red'> Only ${zeroPad(((hours * 60) + minutes))} minutes left </p>`);
      }
    }
  }, [timeLeft]);



  const uploadQueuedFiles = (dropzoneFileInfo) => {
    if(dropzoneFileInfo.length && dropzoneFileInfo.every(item => item.status !== 'preparing')){
      autoHandleSubmit(dropzoneFileInfo,dropzoneFileInfo,props.data);
      setDropzoneFileInfo([])
    }
  }

  const autoHandleSubmit = (files, allFiles, row) => {
      // setFileSubmitted(false);
      Swal.close();
      Swal.fire({
        icon: "info",
        title: 'Processing File...',
        text: 'Upload may take a bit longer than usual',
        showConfirmButton: false
      });
      if(files.length){
        const formInputs = new FormData();
        files.map((f, index) => {
          formInputs.append('file_' + index, f.file)
        })
        formInputs.append('assignmentRow1', JSON.stringify(row));
        AssignmentSubmission(formInputs)
          .then((res) => {
            setDropzoneFileInfo([])
            props.setToFinal(true)
            // setAssignmentReload(getRandomInt(5000));
            Swal.close();
            Swal.fire({
              icon: "success",
              title: 'Submission Received',
              text: 'To Finalize your submission please set the status to Final.',
              showConfirmButton: true
            }).then((result) => {
            });
            // setFileSubmitted(true);
          })
          .catch((error) => {
            if(error.response && error.response.status && error.response.status ==400 && error.response?.data?.alreadySubmitted==true){
              Swal.fire({
                icon: "info",
                title: "Assessment already submitted",
                text: error.response.data.message,
              }).then(()=>{
                // initiateReload()
              })
            }else{
              Swal.fire({
                icon: "error",
                title: "Submission Could not be Completed.",
              }).then((result) => {});
            }
          });
      }else{
        Swal.close();
        // setFileSubmitted(true);
      }
      // stop()
    }

  const intervalRef = useRef(null);

  const getCurrentDropzoneFileInfo = () => dropzoneFileInfo;

  // Function to properly calculate the time left for the assessment
  // Fix: Better time calculation to prevent premature auto-submission
  const calculateTimeLeft = (data, string = false) => {
    // Check if data is loaded before doing calculations
    if (!data) {
      console.log("Data not loaded yet");
      return string ? "--:--:--" : {hours: null, minutes: null, seconds: null};
    }
    const now = moment().unix(); 
    let timeLeft = null;
    
    // Calculate time left based on close time (if available)
    if (data.close_date_time) {
      let closeTimeUnix;
      if (typeof data.close_date_time === 'number') {
        closeTimeUnix = data.close_date_time;
      }
      else if (!isNaN(Number(data.close_date_time))) {
        closeTimeUnix = Number(data.close_date_time);
      }
      else {
        closeTimeUnix = moment(data.close_date_time).unix();
      }
      
      // Calculate time remaining until close time
      if (closeTimeUnix && !isNaN(closeTimeUnix)) {
        let timeLeftFromCloseTime = closeTimeUnix - now;

        // If timeLeft hasn't been set yet, or if this time is shorter, use it
        if (timeLeft === null || timeLeftFromCloseTime < timeLeft) {
          timeLeft = Math.max(0, timeLeftFromCloseTime);
        }
      }
    }
    
    // Calculate time left based on time limit (if active)
    if (data.timeLimitActive === 1 && data.startTime && data.startTime > 0) {
      const timeLimitInSeconds = ((data?.timeHour || 0) * 60 * 60) + ((data?.timeMin || 0) * 60);
      const elapsedTime = now - data.startTime;
      const timeLeftFromTimeLimit = timeLimitInSeconds - elapsedTime;
      
      if (timeLeft === null || timeLeftFromTimeLimit < timeLeft) {
        timeLeft = Math.max(0, timeLeftFromTimeLimit);
      }
    }
    
    // If we still don't have a valid timeLeft, it means the required data isn't available
    if (timeLeft === null) {
      console.log("Time data not fully loaded yet");
      return string ? "--:--:--" : {hours: null, minutes: null, seconds: null};
    }
    // Check if timeLeft is greater than the maximum time defined by timeHour and timeMin
    const maxTimeInSeconds = ((data?.timeHour || 0) * 60 * 60) + ((data?.timeMin || 0) * 60);
    if (maxTimeInSeconds > 0 && timeLeft > maxTimeInSeconds) {
      return string ? "--:--:--" : {hours: null, minutes: null, seconds: null};
    }
    
    // Convert seconds to hours, minutes, seconds
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = Math.floor(timeLeft % 60);
    
    if (string) {
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return {hours, minutes, seconds};
    }
  }

  // Fix for the useEffect that monitors time for non-time-limited assessments
  useEffect(() => {
    if (props.assessmentId) {
      let aid = props.assessmentId;
    
      // Clear the previous interval before setting a new one
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    
      if (props.data.timeLimitActive === 1) {
        if (isTimerActive || props.data.launched === 1) {
          
          GetAssignmentDetail({ aid })
            .then((res) => {
              setAssessmentStatus(res.data.status);
              intervalRef.current = setInterval(() => {
                let timeRemaining = calculateTimeLeft(res.data, true);
                setTimeLeft(timeRemaining);
                
                // Only set time up if the time is actually 00:00:00
                if (timeRemaining === "00:00:00") {
                  setHours(0);
                  setMinutes(0);
                  setSeconds(0);
                  setIsTimeUp(true);
                  setDisableFileUpload(true);
                  uploadQueuedFiles(dropzoneFileInfoRef.current);
                  setAssessmentStatus("Closed Not-Submitted");
                  console.log("Times up");
                  clearInterval(intervalRef.current);
                }
              }, 1000);
            })
            .catch((error) => {});
        }
      } else {
        GetAssignmentDetail({ aid })
          .then((res) => {
            setAssessmentStatus(res.data.status);
            intervalRef.current = setInterval(() => {
              // Calculate remaining time from close date
              const now = new Date().getTime();
              const closeTime = new Date(res.data?.close_date_time).getTime();
              const timeLeftFromCloseTime = closeTime - now;
              
              // Only trigger time up if the time is actually up
              if (timeLeftFromCloseTime <= 0) {
                setHours(0);
                setMinutes(0);
                setSeconds(0);
                setIsTimeUp(true);
                setDisableFileUpload(true);
                setAssessmentStatus("Closed Not-Submitted");
                uploadQueuedFiles(dropzoneFileInfoRef.current);
                console.log("Times up");
                clearInterval(intervalRef.current);
              }
            }, 1000); // Changed from 5000 to 1000 for more consistent timing
          })
          .catch((error) => {});
      }
    }
  
    // Cleanup function to clear the interval when dependency changes
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isTimerActive, props?.reloadchild]); 
  
  // Fix for the auto-upload when files are queued
  useEffect(() => {
    // Only trigger auto-upload if time is actually up and uploads are disabled
    if(disableFileUpload && isTimeUp){
      uploadQueuedFiles(dropzoneFileInfo);
    }
  }, [dropzoneFileInfo, disableFileUpload, isTimeUp]);

  // Update the ref whenever dropzoneFileInfo changes
  useEffect(() => {
    // console.log("dropzoneFileInfo", dropzoneFileInfo)
    dropzoneFileInfoRef.current = dropzoneFileInfo;
  }, [dropzoneFileInfo]);

  const handleChangeStatus = ({ meta, file }, status) => {
    let tempArr = [...dropzoneFileInfo]
    if(status == 'removed'){
      tempArr = tempArr.filter(item => meta.id != item.meta.id)
    }else if(status == 'preparing'){
      tempArr = tempArr.filter(item => !(meta.id === item.meta.id || (meta.name === item.meta.name && meta.size === item.meta.size)));
      tempArr.push({
        meta: meta,
        file: file,
        status: status,
      })
    }else if(status == 'done') {
      tempArr = tempArr.filter(item => !(meta.id === item.meta.id || (meta.name === item.meta.name && meta.size === item.meta.size)));
      tempArr.push({
        meta: meta,
        file: file,
        status: status,
      })
    }
    setDropzoneFileInfo(tempArr)
    
    let fileQueue = {...fileStatus, [meta.id]:status};
    const inProgressStatus = ["preparing", "ready", "ready", "getting_upload_params", "uploading", "restarted"]
    const fileProgressCount = Object.keys(fileQueue).filter(item => inProgressStatus.includes(fileQueue[item])).length;
    setFileInProgress(fileProgressCount);
    if(status!="done" && (props?.data.timeLimitActive && hours==0 && minutes==0 && seconds==0)){
      setDisableFileUpload(true)
    }
    if(inProgressStatus.includes(status)){
      props.setFileSubmitted(false);
    }
    setFileStatus({...fileQueue})    
  }

  useEffect(() => {
    if(disableFileUpload){
      document.querySelectorAll('.dzu-previewButton').forEach(button => button.style.display = 'none')
    }
  },[disableFileUpload,dropzoneFileInfo])

  
  const currentTimeStatus = (h, m, s) => {
    setHours(h)
    setMinutes(m)
    setSeconds(s)
  }

  const TimeLeft = (data) => {
    
    // If we have time data available, use it immediately
    if (data?.timeHour !== undefined && data?.timeMin !== undefined) {
      const h = parseInt(data.timeHour) || 0;
      const m = parseInt(data.timeMin) || 0;
      const s = 0;
      
      // Set the initial time values
      setHours(h);
      setMinutes(m);
      setSeconds(s);
      
      // Also update the formatted timeLeft string
      setTimeLeft(`${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`);
    }
    
    // Then request the updated data from the server
    if (props.assessmentId) {
      setIsTimerActive(true);
      let aid = props.assessmentId;
      GetAssignmentDetail({ aid })
        .then((res) => {
          if (res.data && res.data.remainingDuration > 0) {
            const remainingTime = calculateTimeLeft(res.data, true);
            setTimeLeft(remainingTime);
            
            const {hours, minutes, seconds} = calculateTimeLeft(res.data);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
          }
        })
        .catch((error) => {
          console.error("Error fetching time data:", error);
        });
    }
  }
  
  return (
    <>
      <div className="tab-pane fade show active" id="Assessment-Detail">
        <div className="test-series-main-block">
          <div className="test-series-main-left-blk">
            <div className="test-series-main-heading icon-add mb-15">
              <h4>{props.data && props.data.name ? props.data.name : ""}{props.data && props.data.code ? ` (${props.data.code})` : ""}</h4>
              <span className="test-series-main-left-heading-act">
                <SystemIcons data={props.data} />
              </span>
            </div>
            
            <div className="breadcrumb-btn-blk">
              <div className="custom-breadcrumb">
                <ul>
                  <li className="custom-breadcrumb-item inactive">
                    <a href="#" onClick={(e)=>{e.preventDefault()}}>
                      <i className="fal fa-home"></i>
                    </a>
                  </li>
                  {props.showSystemCheck &&
                    <li className="custom-breadcrumb-item inactive">
                      <a href="#" onClick={(e)=>{e.preventDefault()}}>System Check</a>
                    </li>
                  }
                  {props.showIdentification &&
                    <li className="custom-breadcrumb-item inactive">
                      <a href="#" onClick={(e)=>{e.preventDefault()}}>Student Identification</a>
                    </li>
                  }
                  <li className="custom-breadcrumb-item inactive">
                    <a href="#" onClick={(e)=>{e.preventDefault()}}>Test</a>
                  </li>
                </ul>
              </div>
              <div className="breadcrumb-btns">
                <button onClick={()=>{disableFileUpload ? props.backBtn(true) : props.backBtn()}} type="button" class="btn btn-primary">
                  <i class="fal fa-chevron-left"></i> Back{" "}
                </button>
                <div className="breadcrumb-right-btns">
                  {(props.data.quiz_id == 0 && props.data.launched == 0 && !isTimeUp) ? 
                    props.data.assFile != '' ? 
                      <button
                        type="button"
                        class="btn btn-primary"
                        disabled={props?.loader==true}
                        onClick={()=>{props.launchDownload({isLaunchedAndDownload: true, isOnlyDownload: false});TimeLeft(props.data)}}
                      >
                        {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i class="fal fa-download"></i>}&nbsp; Launch & Download
                      </button>
                    :   
                      <button
                        type="button"
                        class="btn btn-primary"
                        disabled={props?.loader==true}
                        onClick={()=>{props.launchDownload({isLaunchedAndDownload: false, isOnlyDownload: false});TimeLeft(props.data)}}
                      >
                        {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i className="fal fa-rocket"></i>}&nbsp; Launch
                      </button>
                  : null }
                  {props.data.quiz_id == 0 && props.data.launched == 1 && props.data.assFile != '' ?
                    <button
                      type="button"
                      class="btn btn-primary"
                      disabled={props?.loader==true}
                      onClick={()=>{props.launchDownload({isLaunchedAndDownload: false, isOnlyDownload: true})}}
                    >
                      {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i class="fal fa-download"></i>}&nbsp; Download
                    </button>
                  : null}
                </div>
              </div>
            </div>

            <div class="test-series-main-heading mb-15">
              <h4>Details</h4>
            </div>
            <div className="assessment__details__wrapper">
              <div className="custom__accordion__box">
                <div className="accordion__head d-flex align-items-center justify-content-between">
                  <div className="head__bar">
                    { props?.data?.timeLimitActive === 1 && props.data.isSubmitLeft != 0 ? 
                      <i className="fal fa-clock"></i>
                      : <i className="fal fa-ballot-check"></i>
                    }
                    {props?.data?.timeLimitActive === 1 && props.data.isSubmitLeft != 0
                      ? <div className="timer">Time Limit: <span className="as-text-red">
                          {!props.data.launched || props.data.launched !== 1
                            // ? `${String(props?.data?.timeHour || 0).padStart(2, '0')}:${String(props?.data?.timeMin || 0).padStart(2, '0')}:00`
                            ? calculateTimeLeftOpt(props.data)
                            : seconds === null
                              ? `--:--:--`
                              : (hours === '0' && minutes ===' 0' && seconds === '0')
                                ? `00:00:00`
                                : timeLeft
                          }
                      </span>
                      </div>
                      : "Assessment Information" 
                    }
                  </div>
                  <button type="button" onClick={() => setOpen(!open)} className="btn btn-primary">
                    <i className={open ? "fal fa-chevron-up" : "fal fa-chevron-down"}></i>
                  </button>
                </div>
            <div className={"textseries-content-blk white-brd-blk mb-4 "  + (open ? "show" : "")}>
              <div className={`row ${props.quizId ? '' : 'mb-30'}`}>
                <div className="col-md-6">
                  <div className="textseries-info-details">
                    <ul>
                      <li>
                        <span className="heading">Type:</span>
                        <span className="content">
                          {props?.data?.ass_type &&
                            <AssignmentType ass_type={props?.data?.ass_type ? props.data.ass_type : ""} />
                          }
                        </span>
                      </li>
                      <li>
                        <span className="heading">Status:</span>
                        <span className="content">
                          {props?.data?.status &&
                            <AssignmentStatus status={assessmentStatus} />
                          }
                        </span>
                      </li>
                      <li>
                        <span className="heading">Condition:</span>
                        <span className="content">
                          <span className="ts-info-act">
                            <SystemIcons data={props.data} />
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="textseries-info-details">
                    <ul>
                      <li>
                        <span className="heading">Open Date:</span>
                        <span className="content">
                          {props?.data?.open_date_time
                            ? props.data.open_date_time
                            : ""}
                        </span>
                      </li>
                      <li>
                        <span className="heading">Close Date:</span>
                        <span className="content">
                          {props?.data?.close_date_time
                            ? props.data.close_date_time
                            : ""}
                        </span>
                      </li>
                      <li>
                        {props?.data?.timeLimitActive === 1 && <span className="heading">Time Limit:</span>}
                        {props?.data?.timeLimitActive === 1 && <span className="content">{quizTotalTime}</span>}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 mt-15">
                  <div className="textseries-info-details">
                    <ul>
                      <li className="">
                        <span className="heading">Submission:</span>
                        {((props.data.Files && (props.data.Files.length > 0)) || props.data.fileName != "") ?
                          <span onClick={() => setShowFile(!showFile)} className="content">
                            List of files <i class={`fal ${showFile ? 'fa-chevron-down' : 'fa-chevron-up'} `}></i>
                          </span>
                          :
                          <span className="content">
                            No attempt
                          </span>
                        }
                      </li>
                          {props.data.ass_type === "Supplementary Exam" && <li className="">
                            <span className="heading">Maximum Mark Achievable: &nbsp;</span>
                            {props.data.maximum_grade_achievable ?
                              <span className="content">
                                {props.data.maximum_grade_achievable}
                              </span>
                              :
                              <span className="content">
                                -
                              </span>
                            }
                          </li>}
                      {showFile && (props.data.Files && (props.data.Files.length > 0) || props.data.fileName != "") ?
                        <div className="uploded-files-block mt-15">
                          <div className="row">
                            {props.data.Files && (props.data.Files.length > 0) ?
                              props.data.Files.map(i => {
                                i.removeF = props.handleRemoveFile
                                i.isInArray = true
                                i.showClose = true
                                return (
                                  RenderAttachList(i)
                                )
                              })
                              :
                              props.data.fileName != "" ?
                                RenderAttachList({ fileName: props.data.fileName, size: props.data.subFileSize, filePath: props.data.filePath, removeF: props.handleRemoveFile, id: props.data.subID, isInArray: false, subFilePath: props.data.subFilePath, showClose: true })
                                :
                                null}
                          </div>
                        </div>
                        : null}
                      {props.quizId &&
                        <li className="">
                          <span className="heading">Attempts:</span>
                          <span className="content">
                            {props.maxAttempt ? props.maxAttempt : 0}
                          </span>
                        </li>
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
              {(!props.data.quiz_id && props?.data?.launched === 1) && <div className="textseries-content-blk white-brd-blk mb-4">
                <div className={`profile-doc-upload-blk ${props.data.subFilePath !== null && props.data.subFilePath !== '' && (props.data.subTime == null || props.data.subTime == 0) ? 'final_status_change' : ''}`}>
                  <div className="profile-doc-upload" style={{}}>
                    <Dropzone
                      getUploadParams={props.getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      disabled={disableFileUpload}
                      submitButtonDisabled={disableFileUpload}
                      onSubmit={(dropzoneFileInfo, allFiles)=>{props.onSubmit(dropzoneFileInfo, allFiles); setDropzoneFileInfo([])}}
                      classNames={{ submitButton: 'btn btn-primary submit-file-button', submitButtonContainer: ' btn-upload' }}
                      styles={{ dropzone: { /*minHeight: 225*/ } }}
                      accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt,.mp4,.mp3,.otf,.xlsx"
                      // SubmitButtonComponent={null}
                      inputContent={
                        <lable>
                          <i className="fas fa-file-upload"></i>
                          <h4>
                            To submit assessment, Drop your file here, or <span>browse</span>
                          </h4>
                          <p>Supports: PDF, DOCX and more</p>
                        </lable>
                      }
                      maxFiles={10}
                      // LayoutComponent={({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
                      //   console.log(input, "---->", previews, '=========>', dropzoneProps);
                      //   if (previews) {
                      //     return React.useMemo(() => <div>
                      //       {previews}

                      //       <div {...dropzoneProps}>
                      //         {files.length < maxFiles && input}
                      //       </div>

                      //       {files.length > 0 && submitButton}
                      //     </div>)
                      //   }
                      // }
                      // }
                      maxSizeBytes="1200MB"
                      submitButtonContent={<div class="submit-files"><i class="fal fa-check"></i> Submit Files</div>}
                    />
                    {/* {props.data.subFilePath !== null && props.data.subFilePath !== '' && (props.data.subTime == null || props.data.subTime == 0) ? ( */}
                    {!disableFileUpload && props?.data?.Files && props?.data?.Files?.length > 0 && (props.data.subTime == null || props.data.subTime == 0) ? (
                      <div className="btn-action-block examfile-upload-btn">
                        <button
                          onClick={()=>props.setToFinal(false)}
                          type="button"
                          className="btn btn-danger"
                        >
                          {props?.loadFinal==true ? <i className={`fas fa-cog fa-spin`}></i> : <i className="fal fa-check"></i>}&nbsp; Change to Final Status
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
            </div>}
            {(props.data.quiz_id && props?.data?.launched === 1) ?
              <ViewQuestion
                getSubContentMethod={props.getSubContentMethod}
                quizId={props.quizId}
                setQuizId={props.setQuizId}
                setshow={props.setshow}
                setrId={props.setrId}
                rId={props.rId}
                quizArr={quizArr}
                aid={props.assessmentId}
                currentTimeStatus={currentTimeStatus}
                initiateReload={props.initiateReload}
                setIsExamLaunchedToFalse={props.setIsExamLaunchedToFalse}
                isSubmiting={props.isSubmiting}
                stop={props.stop}
                // quizTotalTime={setQuizTotalTime}
                assPassMark={props?.assPassMark}
                gradeType={gradeType}
                submitQuizAssessment={submitQuizAssessment}
                updateSubmitQuizAssessment={updateSubmitQuizAssessment}
              />
              : null
            }
            <div className="textseries-content-blk desc-instruction-blk white-brd-blk mb-15">
              <h4>{Str.detailDes}:</h4>
              {props?.data?.details
                ? ReactHtmlParser(props.data.details)
                : Str.noInstruction}
            </div>
          </div>
          <div className="test-series-main-right-blk">
            <div className="step-sidebar-space">
              <SysCheckSideIcon props={props} />
            </div>

            <div className="test-series-steps">
              <ul>
                <li className="test-series-steps-item active done">
                  <a href="#" onClick={(e)=>{e.preventDefault()}}>
                    <span>1</span> <b>Assessment Detail</b>
                  </a>
                </li>
                {props.showSystemCheck ?
                  <li className="test-series-steps-item active done">
                    <a href="#" onClick={(e)=>{e.preventDefault()}}>
                      <span>2</span> <b>System Check</b>
                    </a>
                  </li>
                  : null}
                {props.showIdentification ?
                  <li className="test-series-steps-item active done">
                    <a href="#" onClick={(e)=>{e.preventDefault()}}>
                      <span>3</span> <b>Identification</b>
                    </a>
                  </li>
                  : null}
                <li className="test-series-steps-item active done">
                  <a href="#" onClick={(e)=>{e.preventDefault()}}>
                    <span>4</span> <b>Launch Exam</b>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Step5;