import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import $ from "jquery";
import myAieVideo from "../assets/images/my-aie-video.jpg";
import { getCurrentTimeHomePage, wishTiming } from "../components/common/Helper";
import TargetScroller from 'react-target-scroller';
import HashHandler from 'react-hash-handler';
import { Link } from "react-router-dom";
import { modalOpen, setFavouritesCount } from "../store/actions";
import { connect } from "react-redux";
import { getHubResources, getAccademicResources, getStudentHubTabs, getAssignStudentResource, OpenedResource } from "../services/StudentHubService.js";
import VeryGoodImage from "../assets/images/very-good.png";
import GoodImage from "../assets/images/good.png";
import AverageImage from "../assets/images/average.png";
import PoorImage from "../assets/images/poor.png";
import VeryPoorImage from "../assets/images/very-poor.png";
import VeryGood from "../components/ComplimentComment/VeryGood";
import Good from "../components/ComplimentComment/Good";
import Average from "../components/ComplimentComment/Average";
import Poor from "../components/ComplimentComment/Poor";
import VeryPoor from "../components/ComplimentComment/VeryPoor";
import { Nav, NavItem, NavLink } from "reactstrap";
import StudentHubLibrary from "./StudentHubLibrary";
/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (payload) => dispatch(modalOpen(payload)),
    setFavCount : (payload) => dispatch(setFavouritesCount(payload))
  };
}

const mapStateToProps = (state) => {
  return { brandConfig: state.brand, favCount: state.favourites.favouritesCount };
};

class StudentHub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      howTo: '',
      feedback: '',
      demoTab: '',
      myLibrary:'',
      tab_id: '',
      academicResources: '',
      rating_type: "VERY GOOD",
      url: this.props.location.pathname + this.props.location.hash,
      isOpen: false,
      videoId: null,
      scrollTarget: null,
      hubResources: [],
      AcademicResource: [],
      isFeatured: {},
      isFeaturedAcademic: {},
      studentHubTabs: [],
      tabstate: '',
      paramObj: {}
    };
  }

  handleRating = (rating) => {
    this.setState({ rating_type: rating });
  };

  clickTab = (id) => {
    if ((id !== "feedback") && (id !== "demoTab") && (id !== "myLibrary")) {
      this.setState({ tab_id: id })
      this.setState({ feedback: "", demoTab: '', myLibrary: '' })

      // load tabs content by tab click
      getHubResources({ tabid: id })
        .then((res) => {
          this.setState({ hubResources: res.data.output, isFeatured: res.data.isFeatured });
          this.setState({
            scrollTarget: this.props.location.hash,
          });
        })
        .catch((error) => {
        });

    }
    else if (id == "demoTab") {
      this.setState({ demoTab: "active", feedback: '',myLibrary: '' })
      this.setState({ tab_id: "" })
    } else if (id == "myLibrary") {
      this.setState({ myLibrary: "active", feedback: '',demoTab: '' })
      this.setState({ tab_id: "" })
    } else {
      this.setState({ feedback: "active", demoTab: '',myLibrary: '' })
      this.setState({ tab_id: "" })
    }
  };

  openModal = (video, type) => {
    let link = `https://player.vimeo.com/video/${video}`;
    if (type == 'youtube') {
      link = `https://www.youtube.com/watch?v=${video}`
    }
    this.props.modalOpen(link);
  }

  onHashChange = ({ hash }) => {
    this.setState({
      scrollTarget: `#${hash}`,
    });
  }
  // onHashClick=({hash})=> {
  //   this.setState({
  //     scrollTarget: `#${hash}`,
  //   });
  // }

  static getDerivedStateFromProps(props, state) {
    if ((props.match?.params?.tab !== state.tabstate)||(props.match?.params?.id !== state.paramObj.id)) {
      state.tabstate = props.match.params.tab
      state.paramObj = props.match.params
      // return{
      //   tabstate: props.match.params.tab
      // };
    }
    return null; // No change to state
  }

  componentDidMount() {
    // const {match}  = this.props;
    // console.log('the value of the current tab should be ', match.params.tab)

    if (this.props.location.hash != '') {
      setTimeout(() => {
        var elmnt = document.getElementById(this.props.location.hash.replace("#", ""));
        elmnt.scrollIntoView()
      }, 1000);
    }

    let video = this.props.location.pathname.split('/')[2];
    let text = this.props.location.pathname.split('/')[3];
    if (video != '' && video != undefined && text == 'video') {
      this.state = {
        isOpen: true,
        videoId: video,
      };
    } else {
      this.state = {
        isOpen: false,
        videoId: null,
      };
    }

    // console.log("this.props.brandConfig.configuration.brand_name: ", this.props.brandConfig.configuration.brand_name);

    // Calling for student hub tabs
    let domain = this.props.brandConfig.configuration.brand_name;

    // let domain ;
    //   if(window.location.hostname.includes("gdcstudentportal.devtechnosys.info") || window.location.host.includes("localhost:3000")){
    //     domain = "aie"
    //   }else if(window.location.hostname.includes("mygdc.ac") || window.location.host.includes("localhost:3001")){
    //     domain = "gdc"
    //   }
    getStudentHubTabs({ domain: domain }).then((res) => {
      this.setState({ studentHubTabs: res.data.output },
        () => {
          // if (this.state.studentHubTabs && this.state.studentHubTabs !== "undefined" && this.state.studentHubTabs.length > 0) {
          //   this.props.history.push(`/student-hub/${this.state.studentHubTabs[0].id}`);
          // } else {
          //   this.props.history.push("/student-hub/feedback");
          // }

          // By default data by tabs 1 on page load 
          if (this.state.studentHubTabs && this.state.studentHubTabs !== "undefined" && this.state.studentHubTabs.length > 0) {
            getHubResources({ tabid: this.state.studentHubTabs[0].id })
              .then((res) => {
                this.setState({ hubResources: res.data.output, isFeatured: res.data.isFeatured });
                this.setState({
                  scrollTarget: this.props.location.hash,
                });
              })
              .catch((error) => {
              });
            // By default active tab
            setTimeout(() => {
              this.state.studentHubTabs.map(item => {
                if (this.props.location.pathname.split('/')[2] == item.id) {
                  this.setState({ tab_id: item.id });
                }
              })
            }, 1000);
          }
          else {
            this.setState({ feedback: "active" })
            this.setState({ tab_id: "" })
          }

        }
      )
    }).catch((error) => {
    });

    this.setState({ demoTab: 'active' })
  }

  // componentDidUpdate() {

  // }

  // componentWillMount() {
  // }

  // componentWillUnmount() {
  // }

  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  render() {
    const {
      scrollTarget,
      isFeatured,
      isFeaturedAcademic,
      url,
      howTo,
      feedback,
      academicResources,
      tab_id,
      demoTab,
      myLibrary
    } = this.state;

    const handleOpenedResourceClick = async (rid,rname)=>{
      const params = { id: rid, name: rname };
      await OpenedResource(params);
    }

    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="sec-help-center">
              <HashHandler
                onChange={this.onHashChange}
                onClick={this.onHashChange}
              />
              <TargetScroller target={scrollTarget} />

              <div className="card-simple-header d-flex border-bottom">
                <h4 className="my-md-2 my-0 landing-heading no-margin heading_color_dynamic">Student <span>Hub</span></h4>
                {/* <span className="ttl-left"> </span> */}
                <div className="lecture-detail-bottom nav-tab-60">
                  <div className="lecture-tabs-wrap">
                    <ul className="nav nav-pills no-border" id="pills-tab" role="tablist">


                      <li className="nav-item" onClick={() => this.clickTab("demoTab")}>
                        <Link className={`nav-link ${demoTab}`} to={`/student-hub/home`}
                        >
                          <i className="fal fa-books"></i>Library
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => this.clickTab("myLibrary")}>
                        <Link className={`nav-link ${myLibrary}`} to={`/student-hub/my-library/table`}
                        >
                          {this.props.favCount > 0 ? <small className="notify-count">{this.props.favCount>9 ? '9+' : this.props.favCount}</small> : null}
                          <i className="fal fa-star"></i>Favourites
                        </Link>
                      </li>
                      {
                        this.state.studentHubTabs.length > 0 ?
                          this.state.studentHubTabs.map((data, index) => {
                            return (
                              <li className="nav-item" key={index} onClick={() => this.clickTab(data.id)}>
                                <Link className={`nav-link ${data.id == this.state.tab_id ? 'active' : ""}`} to={`/student-hub/${data.id}`}
                                >
                                  {data.icon !== "" ? <i className={`${data.icon}`}></i> : <i className="fal fa-layer-plus"></i>}
                                  {data.tab_name}
                                </Link>
                              </li>
                            )
                          }) :
                          null
                      }

                      {/* <li className="nav-item" onClick={() => this.clickTab("academicResources")}>
            <Link className={`nav-link ${academicResources}`} to={`/student-hub/academic-resources`} 
                > 
               <i className="fal fa-layer-plus"></i>Academic Resources
              </Link>
            </li>  */}

                      <li className="nav-item" onClick={() => this.clickTab("feedback")}>
                        <Link className={`nav-link ${feedback}`} to={`/student-hub/feedback`}
                        >
                          <i className="fal fa-handshake-alt"></i>Feedback
                        </Link>
                      </li>

                    </ul>

                  </div>
                </div>
                <span className="ml-auto current-date-time">{getCurrentTimeHomePage(Date().toLocaleString())}</span>
              </div>
              <div className="tab-content" id="pills-tabContent">

                <div
                  className={`tab-pane ${((tab_id != "") && (tab_id != "demoTab") && (tab_id != "myLibrary")) ? "active" : ''}`}
                  id="pills-howTo"
                  role="tabpanel"
                  aria-labelledby="pills-howTo-tab"
                >
                  <div className="card-simple">
                    <div className="card-simple-body">
                      {isFeatured && isFeatured.link && (<div className="sec-watch-video mb-4">
                        <div className="row vcd0">
                          <div className="col-lg-4">
                            <div className="video-column">
                              <Link className="" to={`/${this.props.location.pathname.split('/')[1]}/${this.props.location.pathname.split('/')[2]}/${isFeatured.link}/video`}>
                                <div className="vido-box" onClick={() => this.openModal(isFeatured.link)}>
                                  <img src={myAieVideo} alt="" />
                                  <button className="ply-video">
                                    <i className="fal fa-play"></i>
                                  </button>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="cnt-right">
                              <div className="list-group-video">
                                <div className="card-list-item">
                                  <div className="card-list-ic d-flex mb-3">
                                    <div className="card-media card-blue-1">
                                      <i className="fal fa-video"></i>
                                    </div>
                                    <div className="card-media-text">
                                      <p>{isFeatured.title}</p>
                                      <span><i className="fal fa-clock"></i> {isFeatured.duration} </span>
                                    </div>
                                  </div>
                                  <div className="lead-text">
                                    <p>{isFeatured.description}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>)}

                      {
                        this.state.hubResources.length > 0 && this.state.hubResources.map((item,ind) => {
                          return (
                            <div className="card-list-simple mb-3" key={ind}>
                              <div className="secondary-label" id={`${item.categoryHashValue}`}> {item.categoryName} </div>
                              <div className="row" id={item.categoryHashValue}>
                                {
                                  item.resources.length > 0 && item.resources.map((subItem,index) => {
                                    if (subItem != null) {
                                      return (
                                        <div className="col-lg-4 col-md-6 pa6" key={index}>
                                          <div className="card-video-inner">
                                            <div className="list-group-video">
                                              <div className="card-list-item">
                                                {(() => {
                                                  switch (subItem.type) {
                                                    case 'vimeo':
                                                    case 'youtube':
                                                      return (
                                                        <div className="card-list-ic d-flex">
                                                          <div className="card-media card-blue-1">
                                                            <i className="fal fa-video"></i>
                                                          </div>
                                                          <div className="card-media-text">
                                                            <p>{subItem.title}</p>
                                                            <span><i className="fal fa-clock"></i> {subItem.duration} </span>
                                                          </div>
                                                          <Link className="" to={`/${this.props.location.pathname.split('/')[1]}/${this.props.location.pathname.split('/')[2]}/${subItem.link}/video`} onClick={()=>handleOpenedResourceClick(subItem.id,subItem.title)}>
                                                            <div className="ab-link" onClick={() => this.openModal(subItem.link, subItem.type)}></div>
                                                          </Link>
                                                        </div>
                                                      )
                                                    case 'pdf':
                                                    case 'link':
                                                      return (
                                                        <div className="card-list-ic d-flex">
                                                          <div className="card-media card-blue-1">
                                                            <i className="fal fa-file-pdf"></i>
                                                          </div>
                                                          <div className="card-media-text">
                                                            <p>{subItem.title}</p>
                                                            <span><i className="fal fa-sd-card"></i> {subItem.duration} </span>
                                                          </div>
                                                          <a className="ab-link" href={subItem.link} target="_blank" onClick={()=>handleOpenedResourceClick(subItem.id,subItem.title)}></a>
                                                        </div>
                                                      )
                                                    case 'article':
                                                      return (
                                                        <div className="card-list-ic d-flex">
                                                          <div className="card-media card-blue-1">
                                                            <i className="fal fa-newspaper"></i>
                                                          </div>
                                                          <div className="card-media-text">
                                                            <p>{subItem.title}</p>
                                                          </div>
                                                          <a className="ab-link" href={`/article/${subItem.id}`} target="_blank" onClick={()=>handleOpenedResourceClick(subItem.id,subItem.title)}></a>
                                                        </div>
                                                      )
                                                    default:
                                                      return (
                                                        <div className="card-list-ic d-flex">
                                                          <div className="card-media card-blue-1">
                                                            <i className="fal fa-video"></i>
                                                          </div>
                                                          <div className="card-media-text">
                                                            <p>{subItem.title}</p>
                                                            <span><i className="fal fa-clock"></i> {subItem.duration} </span>
                                                          </div>
                                                          <Link className="" to={`/${this.props.location.pathname.split('/')[1]}/${this.props.location.pathname.split('/')[2]}/${subItem.link}/video`} onClick={()=>handleOpenedResourceClick(subItem.id,subItem.title)}>
                                                            <a className="ab-link" href="javascript:void(0)" onClick={() => this.openModal(subItem.link)}></a>
                                                          </Link>
                                                        </div>
                                                      )
                                                  }
                                                })()}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  })
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>

                <div
                  className={`tab-pane ${demoTab || myLibrary}`}
                  id="pills-demoTab"
                  role="tabpanel"
                  aria-labelledby="pills-demoTab-tab"
                >
                  <StudentHubLibrary tabState={this.state.tabstate} paramObj={this.state.paramObj} />
                </div>

                <div
                  className={`tab-pane ${academicResources}`}
                  id="pills-academicResources"
                  role="tabpanel"
                  aria-labelledby="pills-academicResources-tab"
                >
                  <div className="card-simple">
                    <div className="card-simple-body">
                      {isFeaturedAcademic && isFeaturedAcademic.link && (<div className="sec-watch-video mb-4">
                        <div className="row vcd0">
                          <div className="col-lg-4">
                            <div className="video-column">
                              <Link className="" to={`/${this.props.location.pathname.split('/')[1]}/${this.props.location.pathname.split('/')[2]}/${isFeaturedAcademic.link}/video`}>
                                <div className="vido-box" onClick={() => this.openModal(isFeaturedAcademic.link)}>
                                  <img src={myAieVideo} alt="" />
                                  <button className="ply-video">
                                    <i className="fal fa-play"></i>
                                  </button>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="cnt-right">
                              <div className="list-group-video">
                                <div className="card-list-item">
                                  <div className="card-list-ic d-flex mb-3">
                                    <div className="card-media card-blue-1">
                                      <i className="fal fa-video"></i>
                                    </div>
                                    <div className="card-media-text">
                                      <p>{isFeaturedAcademic.title}</p>
                                      <span><i className="fal fa-clock"></i> {isFeaturedAcademic.duration} </span>
                                    </div>
                                  </div>
                                  <div className="lead-text">
                                    <p>{isFeaturedAcademic.description}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>)}

                      {/* {
                  
                            this.state.AcademicResource.length>0 && this.state.AcademicResource.map(item=>{
                              return(
                                
                                  <div className="card-list-simple mb-3">
                                  <div className="secondary-label" id={`${item.categoryHashValue}`}> {item.categoryName} </div>
                                  <div className="row" id={item.categoryHashValue}>
                              {
                              item.resources.length>0 && item.resources.map(subItem=>{
                              if(subItem!=null){
                                return(
                                  <div className="col-lg-4 col-md-6 pa6">
                                    <div className="card-video-inner">
                                        <div className="list-group-video">
                                            <div className="card-list-item">
                                            {(() => {
                                            switch (subItem.type) {
                                              case 'vimeo':
                                              case 'youtube':
                                                  return (
                                                    <div className="card-list-ic d-flex">
                                                    <div className="card-media card-blue-1">
                                                      <i className="fal fa-video"></i>
                                                    </div>
                                                    <div className="card-media-text">
                                                      <p>{subItem.title}</p> 
                                                      <span><i className="fal fa-clock"></i> {subItem.duration} </span>
                                                    </div>
                                                    <Link className="" to={`/${this.props.location.pathname.split('/')[1]}/${this.props.location.pathname.split('/')[2]}/${subItem.link}/video`}> 
                                                      <a className="ab-link" href="javascript:void(0)" onClick={()=>this.openModal(subItem.link,subItem.type)}></a>
                                                    </Link>
                                                </div>
                                                  )
                                              case 'pdf':
                                              case 'link':
                                                  return (
                                                    <div className="card-list-ic d-flex">
                                                    <div className="card-media card-blue-1">
                                                      <i className="fal fa-file-pdf"></i>
                                                    </div>
                                                    <div className="card-media-text">
                                                      <p>{subItem.title}</p> 
                                                      <span><i className="fal fa-sd-card"></i> {subItem.duration} </span>
                                                    </div>
                                                    <a className="ab-link" href={subItem.link} target="_blank"></a>
                                                    </div>
                                                  )
                                              case 'article':
                                                return (
                                                  <div className="card-list-ic d-flex">
                                                  <div className="card-media card-blue-1">
                                                    <i className="fal fa-newspaper"></i>
                                                  </div>
                                                  <div className="card-media-text">
                                                    <p>{subItem.title}</p> 
                                                  </div>
                                                  <a className="ab-link" href={`/article/${subItem.id}`}  target="_blank"></a>
                                                  </div>
                                                )
                                              default:
                                                  return (
                                                    <div className="card-list-ic d-flex">
                                                    <div className="card-media card-blue-1">
                                                      <i className="fal fa-video"></i>
                                                    </div>
                                                    <div className="card-media-text">
                                                      <p>{subItem.title}</p> 
                                                      <span><i className="fal fa-clock"></i> {subItem.duration} </span>
                                                    </div>
                                                    <Link className="" to={`/${this.props.location.pathname.split('/')[1]}/${this.props.location.pathname.split('/')[2]}/${subItem.link}/video`}> 
                                                      <a className="ab-link" href="javascript:void(0)" onClick={()=>this.openModal(subItem.link)}></a>
                                                    </Link>
                                                    </div>
                                                  )
                                                }
                                            })()}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    )
                                  }
                              })
                              }
                            </div>
                            </div>
                            )
                            })
                            } */}
                    </div>
                  </div>
                </div>

                <div
                  className={`tab-pane ${feedback}`}
                  id="pills-feedback"
                  role="tabpanel"
                  aria-labelledby="pills-feedback-tab"
                >
                  <div className="card-simple">
                    <div className="card-simple-body">
                      <div className="compliment-header">
                        <h3>Rate our service, let our CEO know</h3>
                        <p>
                          We place our
                          client's interests at the forefront of our business. We like
                          to hear what our customers have to say in order to help
                          shape the future of our business and improve levels of
                          service. That is what 'Let our CEO know' is all about, an
                          opportunity for you to pose a question, lay a complaint or
                          compliment directly to our CEO.
                        </p>
                      </div>

                      <div className="compliment-area">
                        <ul
                          className="nav nav-pills nav-pills-custom justify-content-center"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li
                            className="nav-item"
                            onClick={() => this.handleRating("VERY GOOD")}
                          >
                            <a
                              className="active"
                              id="verygood-tab"
                              data-toggle="pill"
                              href="#verygood"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              <img src={VeryGoodImage} alt="" />
                              <p>very good</p>
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() => this.handleRating("GOOD")}
                          >
                            <a
                              id="good-tab"
                              data-toggle="pill"
                              href="#good"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              <img src={GoodImage} alt="" />
                              <p>good</p>
                            </a>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() => this.handleRating("AVERAGE")}
                          >
                            <a
                              id="average-tab"
                              data-toggle="pill"
                              href="#average"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              <img src={AverageImage} alt="" />
                              <p>AVERAGE</p>
                            </a>
                          </li>

                          <li
                            className="nav-item"
                            onClick={() => this.handleRating("POOR")}
                          >
                            <a
                              id="poor-tab"
                              data-toggle="pill"
                              href="#poor"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              <img src={PoorImage} alt="" />
                              <p>POOR</p>
                            </a>
                          </li>

                          <li
                            className="nav-item"
                            onClick={() => this.handleRating("VERY POOR")}
                          >
                            <a
                              id="very-poor-tab"
                              data-toggle="pill"
                              href="#very-poor"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              <img src={VeryPoorImage} alt="" />
                              <p>VERY POOR</p>
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content tbs-main" id="pills-tabContent">
                          <VeryGood rating_type={this.state.rating_type} />
                          <Good rating_type={this.state.rating_type} />
                          <Average rating_type={this.state.rating_type} />
                          <Poor rating_type={this.state.rating_type} />
                          <VeryPoor rating_type={this.state.rating_type} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>

              {/* // } */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StudentHub);