import React from "react";
import ReactHtmlParser from "react-html-parser";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Countdown,{ zeroPad} from "react-countdown";
import {getRandomInt}  from '../../utils/commonFunction.js'
import {
  AssignmentSubmission,
  SetToFinal,
  RemoveFile
} from "../../services/QualificationService";
import Swal from "sweetalert2";

  const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
      <div>
        {previews}

        <div {...dropzoneProps}>
          {files.length < maxFiles && input}
        </div>
  
        {files.length > 0 && submitButton}
      </div>
    )
  }
  const getUploadParams = ({ meta }) => { 
    return {
       url: 'https://httpbin.org/post' 
  } }
  
  const handleChangeStatus = ({ meta, file }, status) => { 
    //console.log(file)
   }

  const handleSubmit = (files, allFiles,row,setAssignmentReload) => {
    Swal.close();
    Swal.fire({
      icon: "info",
      title: 'Processing File...',
      text:'Upload may take a bit longer than usual',
      showConfirmButton: false
    }).then((result) => {
    });
    const formInputs = new FormData();
    files.map((f,index) => {
      formInputs.append('file_'+index, f.file)
    })
    formInputs.append('assignmentRow1', JSON.stringify(row));
    AssignmentSubmission(formInputs)
    .then((res) => {
      setAssignmentReload(getRandomInt(12));
      Swal.close();
      Swal.fire({
        icon: "success",
        title: 'Submission Received',
        text:'To Finalise your submission please set the status to Final.',
        showConfirmButton: true
      }).then((result) => {
      });
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Submission Could not be Completed.",
      }).then((result) => {});
    });
    allFiles.forEach(f => f.remove())
  }

  const handleRemoveFile = (rowId,type,setAssignmentReload,path) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveFile({ rowId,type,path})
          .then((res) => {
            setAssignmentReload(getRandomInt(3));
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Files has been removed successfully",
            }).then((result) => {
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  const setToFinal = (row,setAssignmentReload) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to change the submission to Final Status? You will not be able to edit the submission once you do this.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I am sure'
      }).then(function(result) {
        if (result.value) {
          //console.log(row,row.subID,row.subject);
          SetToFinal({subID:row.subID,subject:row.subject})
          .then((res) => {
            setAssignmentReload(getRandomInt(7));
            Swal.close();
            Swal.fire({
              icon: "success",
              title: 'Status has been changed',
              showConfirmButton: true
            }).then((result) => {});
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Status could not be changed.",
            }).then((result) => {});
          });
        }
      });
  };


const ShowResult = ({ data }) => (
  data.grade == 1 ? (
    data.assessmentMarkPercentage == 0  ? 
    (<div className='assessments-result-box'><i className="fas fa-circle red"></i>Incomplete</div>):
    data.assessmentMarkPercentage >= 50 ? 
    (<div className='assessments-result-box'><i className="fas fa-circle green"></i>Competent</div>): 
    (<div className='assessments-result-box'><i className="fas fa-circle yellow" ></i>Not yet Competent</div>)
    
    ) : (<p>{data.assessmentMarkPercentage}</p>)
);

const AssignmentUpload = (props) => {
    const {setAssignmentReload} = props;
     // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        setAssignmentReload(getRandomInt(9));
        return <span>00:00:00</span>;
      } else {
        //Render a countdown
        return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
      }
    };
    switch (props.row.status) {
      case "Open":
      case "Draft":
        return (
          <>
           { props.row.timeLimitActive==1 && props.row.startTime > 0 ? (
              <div className="row rowAssessment">
              <div className="col-12 assessment-col-left">
                <div className="d-flex flex-wrap assMentbox">
                  <div>
                    <b>Time left for submission:</b>
                    </div>
                    <div className="flex-shrink">
                      <div className="div-timer">
                      {props.row.remainingDuration > 0 ? ( 
                      <Countdown key={props.row.id} 
                      date={Date.now() + props.row.remainingDuration*1000}
                      renderer={renderer}
                      zeroPadTime='2'
                      >
                      </Countdown>):('00:00:00')}
                      </div>
                    </div>
                  </div>
                </div>
            </div>	
          ):null}
            <div className="row rowAssessment">
                <div className="col-12 assessment-col-left">
                { props.row.subFilePath===null || props.row.subFilePath=='' ? (
                    <div className="d-flex flex-wrap assMentbox">
                        <div>
                        <b>Submission:</b>
                        </div>
                        <div className="flex-shrink">
                        <b>No attempt</b>
                        </div>
                    </div>
                  ):( 
                    <div className="d-flex flex-wrap assMentbox">
                      <div>
                      <b>Submission:</b>
                      </div>
                      <div className="flex-shrink">
                        <div className="list-file-wrap">
                          <button data-toggle="collapse" data-target="#download-list-wrap" aria-expanded="false" className="hide List-of-files collapsed"></button>
                            <div className="download-list collapse" id="download-list-wrap">
                            <ul className="list-unstyled attached-file-ul m-0">
                              { props.row.Files.length > 0 ? (
                              props.row.Files.map((item,index) => (
                                <li key={index}>
                                  <i className="fal fa-file"></i> {item.fileName}
                                  <div className="download-list-action-btns">
                                  <a className="btn rounded-circle view-btn" href={item.filePath} target="_blank">
                                      <i className="fal fa-eye"></i>
                                  </a>
                                  <button  onClick={() => handleRemoveFile(item.id,"file_id",setAssignmentReload,item.path)} className="btn btn-danger"><i className="fal fa-trash-alt"></i></button>
                                  </div>
                                </li>
                                )
                              ) 
                              ): (
                              <li key={props.row.subID}>
                                <i className="fal fa-file"></i> {props.row.fileName}
                                <div className="download-list-action-btns">
                                <a href={props.row.filePath} target="_blank" className="btn rounded-circle view-btn">
                                    <i className="fal fa-eye"></i>
                                </a>
                                <button onClick={() => handleRemoveFile(props.row.subID,"sub_id",setAssignmentReload,props.row.subFilePath)} className="btn btn-danger"><i className="fal fa-trash-alt"></i></button>
                                </div>
                              </li>
                              )
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                )}
                </div>
            </div>
            <div className="row rowAssessment">
                <div className="col-12 assessment-col-left">
                    <div className="d-flex flex-wrap assMentbox">
                        <div>
                          <b>Add Submission:</b>
                        </div>
                        <div className="flex-shrink">
                            <div className="fmInput">
                            { props.row.timeLimitActive == 1 ? (props.row.startTime !== null && props.row.remainingDuration > 0 ? (
                            <Dropzone
                              getUploadParams={getUploadParams}
                              onChangeStatus={handleChangeStatus}
                              onSubmit={(files, allFiles)=>handleSubmit(files, allFiles,props.row,setAssignmentReload)}
                              styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                              accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt"
                              inputContent="Drop files here"
                              maxFiles={10}
                              LayoutComponent={Layout}
                              maxSizeBytes="500MB"
                              submitButtonContent="Submit Files"
                            />)
                            
                            : "Not Available")
                            
                            :(<Dropzone
                              getUploadParams={getUploadParams}
                              onChangeStatus={handleChangeStatus}
                              onSubmit={(files, allFiles)=>handleSubmit(files, allFiles,props.row,setAssignmentReload)}
                              styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                              accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt"
                              inputContent="Drop files here"
                              maxFiles={10}
                              LayoutComponent={Layout}
                              maxSizeBytes="500MB"
                              submitButtonContent="Submit Files"
                            />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { props.row.subFilePath!==null && props.row.subFilePath!=='' && (props.row.subTime == null || props.row.subTime == 0) ? (
              <div className="row rowAssessment">
              <div className="col-12 assessment-col-left">
                <div className="d-flex flex-wrap assMentbox">
                  <div>
                    <b>Change Status:</b>
                  </div>
                  <div className="flex-shrink">
                   <p>When changing to final status you will not be able to change or replace your assignments</p>
                   <br/>
                   <button onClick={() => setToFinal(props.row,setAssignmentReload)} className="btn btn-submit btn-danger">Change to Final Status</button>
                  </div>
                  </div>
                </div>
            </div>	
          ):null}
          </>
        );
        break;
      case "Submitted":
      case "Closed-Submitted":
        return (
          <>
            <div className="row rowAssessment">
              <div className="col-12 assessment-col-left">
                <div className="d-flex flex-wrap assMentbox">
                  <div>
                    <b>Submission:</b>
                  </div>
                  <div className="flex-shrink">
                      <div className="list-file-wrap">
                          <button data-toggle="collapse" data-target="#download-list-wrap" aria-expanded="false" className="hide List-of-files collapsed"></button>
                            <div className="download-list collapse" id="download-list-wrap">
                            <ul className="list-unstyled attached-file-ul m-0">
                              { props.row.Files.length>0 ? (
                              props.row.Files.map(item => (
                                <li>
                                  <i className="fal fa-file"></i> {item.fileName}
                                  <div className="download-list-action-btns">
                                  <a href={item.filePath} target="_blank">
                                    <button className="btn rounded-circle view-btn">
                                      <i className="fal fa-eye"></i>
                                    </button>
                                  </a>
                                  </div>
                                </li>
                                )
                              ) 
                              ): (
                              <li>
                                <i className="fal fa-file"></i> {props.row.fileName}
                                <div className="download-list-action-btns">
                                <a href={props.row.filePath} target="_blank">
                                  <button className="btn rounded-circle view-btn">
                                    <i className="fal fa-eye"></i>
                                  </button>
                                </a>
                                </div>
                              </li>
                              )
                              }
                            </ul>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;
        case "Closed-Marked":
          return (
            <>
              <div className="row rowAssessment">
                <div className="col-12 assessment-col-left">
                  <div className="d-flex flex-wrap assMentbox">
                    <div>
                      <b>Submission:</b>
                    </div>
                    <div className="flex-shrink">
                        {props.row.Files.length>0 ? (
                          <div className="list-file-wrap">
                            <button data-toggle="collapse" data-target="#download-list-wrap" aria-expanded="false" className="hide List-of-files collapsed"></button>
                              <div className="download-list collapse" id="download-list-wrap">
                                <ul className="list-unstyled attached-file-ul m-0">
                                {props.row.Files.map(item => (
                                  <li>
                                    <i className="fal fa-file"></i> {item.fileName}
                                    <div className="download-list-action-btns">
                                    <a href={item.filePath} target="_blank">
                                      <button className="btn rounded-circle view-btn">
                                        <i className="fal fa-eye"></i>
                                      </button>
                                    </a>
                                    </div>
                                  </li>
                                ))}
                                </ul>
                              </div>
                          </div>
                          ):props.row.fileName!='' ? (
                          <div className="list-file-wrap">
                            <button data-toggle="collapse" data-target="#download-list-wrap" aria-expanded="false" className="hide List-of-files collapsed"></button>
                            <div className="download-list collapse" id="download-list-wrap">
                              <ul className="list-unstyled attached-file-ul m-0">
                                <li>
                                <i className="fal fa-file"></i> {props.row.fileName}
                                <div className="download-list-action-btns">
                                <a href={props.row.filePath} target="_blank">
                                  <button className="btn rounded-circle view-btn">
                                    <i className="fal fa-eye"></i>
                                  </button>
                                </a>
                                </div>
                              </li>
                              </ul>
                            </div>
                          </div>
                        ):('No file available')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row rowAssessment">
              <div className="col-12 assessment-col-left">
                <div className="d-flex flex-wrap assMentbox">
                  <div>
                    <b>Your Result</b>
                    </div>
                    <div className="flex-shrink">
                    <ShowResult data={props.row} />
                    </div>
                  </div>
                </div>
            </div>	
            
            <div className="row rowAssessment">
            <div className="col-12 assessment-col-left">
              <div className="d-flex flex-wrap assMentbox">
                <div>
                  <b>Feedback</b>
                  </div>
                  <div className="flex-shrink">
                  {props.row.returnFile !='' && props.row.returnFile!=null ? (
                      <div className="download-list-action-btns">
                      <a href={
                        "https://corona.myaie.ac/" + 
                        // remove https:// and http:// from props.row.returnFile and concatenate with https://corona.myaie.ac/ if it has http or https
                        props.row.returnFile.replace(/^https?:\/\//, "").replace(/^http?:\/\//, "")                        
                        } target="_blank">
                      <i className="fal fa-file"></i> {props.row.returnFileName}
                        {/* <button className="btn rounded-circle view-btn">
                          <i className="fal fa-eye"></i>
                        </button> */}
                      </a>
                      </div>
                  ):('-')}
                  </div>
                </div>
              </div>
            </div>	
            
            <div className="row rowAssessment">
              <div className="col-12 assessment-col-left">
                <div className="d-flex flex-wrap assMentbox">
                  <div>
                    <b>Marker's Comment</b>
                    </div>
                    <div className="flex-shrink">
                    {props.row.subDetails!='' && props.row.subDetails!=null ? (
                      ReactHtmlParser(props.row.subDetails)
                    ):('-')}
                    </div>
                  </div>
                </div>
            </div>	
            </>
          );
          break;
        case "Closed Not-Submitted":
            return (
              <>
                {props.row.timeLimitActive==1 && props.row.startTime > 0 ? (
                  <div className="row rowAssessment">
                    <div className="col-12 assessment-col-left">
                      <div className="d-flex flex-wrap assMentbox">
                        <div>
                          <b>Time left for submission:</b>
                          </div>
                          <div className="flex-shrink">
                            <div className="div-timer">
                            00:00:00
                          </div>
                        </div>
                      </div>
                    </div>
                </div>	
              ):null}
                <div className="row rowAssessment">
                  <div className="col-12 assessment-col-left">
                      <div className="d-flex flex-wrap assMentbox">
                        <div>
                          <b>Submission:</b>
                        </div>
                        <div className="flex-shrink">
                          <b>No attempt</b>
                        </div>
                      </div>
                  </div>
                </div>
              </>
            );
            break;
        case "Pending":
          return (
            <>
              <div className="row rowAssessment">
                <div className="col-12 assessment-col-left">
                  <div className="d-flex flex-wrap assMentbox">
                    <div>
                      <b>Time left</b>
                      </div>
                      <div className="flex-shrink">
                      <b>Not Started Yet</b>
                      </div>
                    </div>
                  </div>
              </div>	
            </>
          );
          break;
        default:
        return (
          <>
          <div className="row rowAssessment">
            <div className="col-12 assessment-col-left">
                <div className="d-flex flex-wrap assMentbox">
                  <p className="as-red">Error!</p>
                </div>
            </div>
          </div>          
          </>
        );
        break;
    }
}
export default AssignmentUpload;
